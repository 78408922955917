.fl {
  float: left;
}

.fr {
  float: right;
}

// ul li {
//   float: left;
//   list-style: none;
// }

.crumbsW {
  height: 40px;
  background: #F9F9F9;
}

.crumbs,
.catAll {
  width: 1400px;
  margin: auto;
}

.catTitle {
  width: 1400px;
  height: 50px;
  line-height: 50px;
  margin: auto;
  padding-left: 20px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
}

.catType {
  overflow: hidden;
  margin: auto;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;

  .checkAll {
    margin-left: 30px;
    line-height: 57px;

    input {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }

  .lj-item {
    width: 127px;
    margin-left: 79px;
  }

  .lj-info {
    width: 178px;
  }

  .lj-lv,
  .lj-price,
  .lj-number,
  .lj-weight,
  .lj-subtotal,
  .lj-operation {
    width: 147px;
  }

  .td-inner {
    text-align: center;
    line-height: 57px;
  }
}

.shopItem {
  width: 1400px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  margin-bottom: 10px;

  .shopName {
    display: flex;
    align-items: center;
    padding-left: 37px;
    width: 1400px;
    height: 40px;
    background: #F9F9F9;
    border: 1px solid #EEEEEE;

    input {
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;

    }

    img {
      margin: 18px 11px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }

    .name {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
    }
  }

  .shopInfo {
    width: 1400px;
    height: 110px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
  }
}




.checkAll {
  margin-left: 30px;
  line-height: 57px;

  input {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

.lj-item {
  min-width: 127px;
  margin-left: 28px;

  img {
    width: 70px;
    height: 70px;
    margin-right: 9px;
    margin-left: 17px;
  }

  .itemNameW {
    width: 116px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical
  }
}

.lj-info {
  width: 178px;
}

.lj-lv,
.lj-price,
.lj-weight,
.lj-subtotal,
.lj-operation {
  width: 147px;
  .del{
    cursor: pointer;
  }
}

.td-inner {
  text-align: center;
}

.lj-number {
  min-width: 147px;

  // display: flex;
  .td-inner {

    .num {
      width: 125px;
      height: 30px;
      display: flex;

      .ant-btn {
        padding: 0;
        width: 35px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        color: #909090;
      }

      .numadd,
      .numsub {
        width: 35px;
        height: 30px;
        border: 1px solid #EEEEEE;
        background: #FFFFFF;
        display: block;
      }

      .numInp {
        width: 50px;
        height: 100%;
        text-align: center;
        border: 1px solid #EEEEEE;
        border-left: none;
        border-right: none;
      }
    }
  }
}

.shopItemBottom {
  width: 1400px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 25px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;

  .quan {
    margin-right: 40px;
  }

  .del {
    width: 50px;
    margin-right: 40px;
  }

  .delsx {
    width: 90px;
  }

  .itemRight {
    min-width: 450px;
    height: 60px;

    .yx {
      margin-right: 28px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }

    .hj {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }

    .jq {
      color: #DE3232;
    }

    .js {
      cursor: pointer;
      width: 150px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      margin-left: 10px;
      background: #909090;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}