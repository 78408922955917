
#inlogin{
  .tablogin{
    width: 500px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    display: flex;
    text-align: center;
    >div{
      flex: 1;
      height: 100%;
      line-height: 60px;
      float: left;
      a{
        display: inline-block;
        width: 100%;
        height: 100%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
    >div:nth-child(1){
      border-right: 1px solid #EEEEEE;
    }
  }
  .switch{
    padding: 30px 20px;
  }
}