.geren,
.qiye {
  text-align: left;

  .ant-form {
    .ant-form-item {
      .ant-form-item-required {
        height: 50px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .ant-form-item-control-input-content {
    height: 50px;

    .div {
      padding: 0px 21px;
      width: 800px;
      height: 50px;
      line-height: 50px;
      background: #F9F9F9;
      border: 1px solid #EEEEEE;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #000000;
    }

    .ant-row {
      .ant-col {
        img {
          width: 300px;
          height: 200px;
        }
      }
    }
  }

  .ant-form-item-control-input {
    min-height: 40px;
  }

  .bo {
    width: 400px
  }

  .tu {
    height: 226px;

    .ant-upload.ant-upload-select-picture-card {
      width: 300px;
      height: 200px;
    }
  }

  .an {
    margin-top: 60px;
  }
}

.qiye .tu {
  text-align: left;
}