.questiondetails {
  width: 1400px;
  margin: auto;

  .wenti {
    width: 1400px;
    min-height: 130px;

    .rcserveList {
      // overflow: hidden;
      display: flex;
      width: 1400px;
      top: 50px;
      // transform: translate(0, 0);
      // position: absolute;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .tiwen {
        top: 125px;
        right: 10px;
        z-index: 999;
        position: fixed;
        width: 89px;
        height: 89px;

        img {
          width: 100%;
          height: 100%;
          image-rendering: -moz-crisp-edges;
          /* Firefox */
          image-rendering: -o-crisp-edges;
          /* Opera */
          image-rendering: -webkit-optimize-contrast;
          /*Webkit (non-standard naming) */
          image-rendering: crisp-edges;

          -ms-interpolation-mode: nearest-neighbor;
          /* IE (non-standard property) */


        }
      }

      .item {
        margin-bottom: 10px;
        // float: left;
        padding: 20px;
        width: 1400px;
        min-height: 130px;
        background: rgba(218, 29, 15, 0.05);
        border-radius: 4px;

        .itemTop {
          display: flex;

          .wen {
            float: left;
            margin-right: 11px;
            line-height: 25px;
            text-align: center;
            width: 25px;
            height: 25px;
            background: #DA1D0F;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
          }

          .title {
            width: 100%;
            float: left;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }

        .pic {
          margin-top: 9px;

          img {
            margin-right: 10px;
            width: 70px;
            height: 70px;
            border-radius: 4px;
          }
        }

        .zhong {
          padding-left: 35px;
          margin-bottom: 10px;
          display: flex;

          .tou {
            margin-right: 11px;
            width: 25px;
            height: 25px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }
        }

        .content {
          display: flex;
          margin-bottom: 29px;

          .da {
            text-align: center;
            line-height: 25px;
            margin-right: 10px;
            width: 25px;
            height: 25px;
            background: #FF9500;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
          }

          .daan {
            width: 100%;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }



        .itemBottom {
          padding-left: 35px;
          display: flex;
          // justify-content: space-between;

          .look {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }

          .time {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }
        }

      }
    }
  }

  .revert {
    padding: 20px;
    width: 1400px;
    // height: 919px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

    .revertIpt {
      position: relative;
      display: flex;
      justify-content: space-between;

      .aite {
        position: absolute;
      }

      .revertText {
        padding: 20px;
        width: 1000px;
        height: 100px;
        background: #F9F9F9;
        resize: none;
        border: none;
      }

      .plbtn {
        margin-right: 30px;
        margin-top: 25px;
        text-align: center;
        line-height: 40px;
        width: 100px;
        height: 40px;
        background: #EEEEEE;
        border-radius: 4px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #909090;
        cursor: pointer;
      }

      .active {
        background: #C33529;
        margin-right: 30px;
        margin-top: 25px;
        text-align: center;
        line-height: 40px;
        width: 100px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .allComment {
    margin-top: 19px;
    min-height: 34px;

    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #000000;

    .all {
      padding-bottom: 19px;
      border-bottom: 1px solid #EEEEEE;

      span {
        color: #909090;
      }
    }

    .list {
      min-height: 100px;

      .item {
        border-bottom: 1px solid #EEEEEE;
        padding: 18px 0;

        .tworeplyList {
          margin-top: 14px;
          .ant-collapse{
            .ant-collapse-item{
              .ant-collapse-header{
                background: #FFF;
              }
            }
          }
          .tworeplyItem {
            padding: 20px;
            margin-bottom: 5px;
            width: 1312px;
            height: 82px;
            background: #F9F9F9;

            .tworeplyname {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              font-weight: bold;
              color: #000000;
            }

            .tworeplycontent {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: #505050;
            }
          }
        }

        .itemTop {
          display: flex;
          justify-content: space-between;

          .itemLeft {
            display: flex;

            .tou {
              margin-right: 10px;
              width: 40px;
              height: 40px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

            .info {
              .name {
                font-size: 14px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: #000000;
              }

              .time {
                display: flex;
                font-size: 12px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: #909090;

                .zan {
                  margin-left: 20px;
                  display: flex;
                  align-items: flex-end;
                  cursor: pointer;

                  img {
                    margin-bottom: 4px;
                    margin-right: 5px;
                  }

                  .zannum {
                    font-size: 12px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #909090;
                  }

                }
              }
            }


          }


          .huifuright {
            text-align: right;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            cursor: pointer;
            color: #C33529;
          }
        }

        .content {

          margin-left: 50px;
          margin-top: 14px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #505050;
        }
      }
    }
  }
}