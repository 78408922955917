#forget-password{
  .tablogin{
    width: 500px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    position: relative;
    div{
      width: 100%;
      height: 100%;
      line-height: 60px;      
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }
    .del {
      position: absolute;
      top: 20px;
      left: 30px;
      cursor: pointer;
      *{
        font-size: 20px;
      }
    }
  }
  .switch{
    padding: 30px 20px;
    .confirm{
      margin-bottom: 40px;
    }
  }
}