.rankings {
  width: 450px;
  display: contents;
  .one {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      height: 30px;
      line-height: 30px;
      font-size: 32px;
      font-family: BDZongYi-A001;
      font-weight: normal;
      color: #000000;
    }

    hr {
      width: 180px;
      background-color: #C7C7C7;
    }

    .right {
      .button+.button {
        margin-left: 20px;
      }
    }
  }

  .date {
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #909090;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .list {
    width: 100%;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 445px;
      height: 110px;
      background: #F7F7F7;
      margin-bottom: 10px;
      padding: 15px 20px;

      img {
        width: 80px;
        height: 80px;
        margin-right: 10px;
      }

      .zhong {
        flex: 1;
        height: 100%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        div{
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .address {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
        }
      }

      .qian {
        height: 24px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #BD2C21;
      }
    }
  }
}