.accountSet {
  width: 1220px;
  height: 601px;
  background: #ffff;
  border: 1px solid #e9e9e9;

  .accountSetItem {
    display: flex;
    align-items: center;
    width: 1190px;
    height: 120px;
    margin-left: 30px;
    background: #ffffff;
    border-bottom: 1px solid #dadbdd;

    .accountSetItem-name {
      width: 114px;
      height: 20px;
      margin-right: 30px;

      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: right;
    }

    .shu {
      width: 1px;
      height: 34px;
      background: #D8D8D8;
    }

    .shuright {
      margin-left: 30px;
      img{
        width: 48px;
        height: 48px;
        
      }
    }

    .accountSetItem-type {
      cursor: pointer;
      height: 40px;
      background: #FFFFFF;
      border-radius: 20px;
      border: 1px solid #D8D8D8;
      // flex: 1;
      font-size: 14px;
      line-height: 40px;
      padding: 0 27px;
      text-align: right;
      margin-right: 30px;
      margin-left: auto;
      .ant-upload{
        width: 100%;
        height: 100%;
        border: none;
        background: #FFF;
        margin: 0;
        .ant-upload-text{
          line-height: 38px;
          font-size: 14px;
        }
      }

    }
  }


}

.statusIdModal{
  .ant-modal-content {
    width: 384px;
    height: 297px;
  
    .ant-modal-footer {
      text-align: center;
      margin: 16px auto 0;
      border-top: none;
  
      .ant-btn {
        width: 124px;
        height: 44px;
        background: #D3040F;
        border-radius: 4px;
      }
    }
  }
}

.Popout {
  width: 384px;
  height: 297px;
  background: #ffff;
  border-radius: 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .popTitle {
    width: 384px;
    height: 44px;
    padding: 0 20px;
    background: #F6F6F6;
    border-radius: 10px 12px 0px 0px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      :hover {
        cursor: pointer
      }

      img {
        width: 13px;
        height: 13px;
        vertical-align: none;
      }
    }
  }

  .popBody {
    padding: 0 20px;
    height: 100%;

  }

  .deal {
    margin-top: 35px;
    margin-bottom: 30px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}

.identityList {
  margin-top: 30px;
  display: flex;

  .ant-radio-group {
    display: flex;
    justify-content: space-between;
    width: 250px;
  }
}

.gaimima {
  .ant-modal-footer{
    margin-top: 0;
  }
  .ant-modal-content {
    width: 384px;
    height: 394px;
    background: #FFFFFF;
    border-radius: 12px;
    .ant-modal-body {
      padding: 20px;
      .gaimimeg{
        margin-bottom: 20px;
      }
    }
  }
  .xgmm{
    .yanzhengma {
      margin-bottom: 20px;
      overflow: hidden;
      input {
        padding-left: 10px;
        float: left;
        width: 224px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #DADBDD;
      }
    
      button {
        cursor: pointer;
        border: none;
        float: right;
        width: 110px;
        height: 32px;
        background: #F6F6F6;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
    }
    
    .shuruNewPassword {
      margin-bottom: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    
      input {
        padding-left: 10px;
        width: 258px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #DADBDD;
      }
    }
    
    .querenNewPassword {
      margin-bottom: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    
      input {
        padding-left: 10px;
        width: 258px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #DADBDD;
      }
    }
    
    .queren {
      width: 124px;
      height: 44px;
      background: #D3040F;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 22px;
      letter-spacing: 1px;
    }
  
  }
}