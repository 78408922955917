.payment {
  width: 1400px;
  margin: auto;

  .top {
    height: 260px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 40px 110px 20px 30px;
    margin: 40px 0 30px;
    display: flex;

    .right {
      margin-left: 20px;
      flex: 1;

      .on1 {
        font-size: 26px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #000000;
      }

      .on2 {
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
        margin-top: 10px;

        span {
          color: #C33529;
        }
      }

      .hr {
        background-color: #EEEEEE;
        height: 1px;
        margin-top: 30px;
      }

      .right2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #808080;
        line-height: 36px;

        .left2 {
          margin-left: 30px;
        }
      }
    }
  }

  .top2 {
    height: 400px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    margin-bottom: 100px;
    padding: 0 110px 0 90px;

    .on1 {
      height: 70px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;

      >span:nth-child(2) {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #C33529;
      }
    }

    .on2 {
      padding-top: 20px;
      margin-bottom: 20px;

      .ant-radio-group {
        width: 100%;
      }

      .img1 {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }

    .botton1 {
      margin-left: 20px;
    }
  }
}

.QRCodeModal {
  .ant-modal-content {
    width: 370px;
    margin: auto;
    .ant-modal-header {
      background-color: #f5f5f5;
      font-size: 18px;
      font-weight: 400;
      color: #424242;
      vertical-align: middle;
    }

    .ant-modal-body {
      padding-bottom: 6px;
      text-align: center;

      .wx_msgDiv {
        margin-top: 16px;

        .wx_msgSpan {
          color: #ff6700;
          cursor: pointer;
        }
      }

    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-evenly;
      border: none;
    }
  }
}