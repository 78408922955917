.piechartList {
  width: 1400px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
  .piechart {
    padding: 0 20px;
    width: 690px;
    height: 450px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);
    .piechartTop {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #EEEEEE;
  
      .left {
        display: flex;  
        line-height: 30px;
  
        .red {
          width: 8px;
          height: 30px;
          background: #E64F38;
          margin-right: 15px;
        }
  
        .title {
          font-size: 28px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: #000000;
        }
      }
    }
    .item{
      width: 650px;
      height: 380px;
    }
  }
}