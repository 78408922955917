.mydizhi {
  min-height: 938px;
  border: 1px solid #E9E9E9;
  padding: 0 22px;

  .biaoti {
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4A4A4A;
    line-height: 22px;
    letter-spacing: 1px;
    border-bottom: 1px solid #EEEEEE;
  }

  .shouhuobiaoti {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D3040F;
    line-height: 22px;
    letter-spacing: 1px;
  }
}