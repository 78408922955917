.myserve {
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  .ant-tabs {
    .ant-tabs-nav {
      background: #F9F9F9;
      border: 1px solid #EEEEEE;

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            width: 150px;
            height: 50px;
            justify-content: center;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }
}