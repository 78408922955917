.shangpinshoucang {
  .list {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    padding: 20px 0;
}
  .item111 {
    width: 280px;
    height: 340px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.3);
    padding: 20px 15px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 0 !important;

    &:nth-child(5n) {
      margin-right: 0px;
    }

    .img {
      width: 100%;
      height: 240px;
    }

    .jia {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #BD2C21;
      text-align: center;
      span {
        font-size: 24px;
      }
    }

    .you {


      .youpin {
        width: 70px;
        height: 25px;
        background: rgba(189, 44, 33, 0.05);
        border: 1px dashed #BD2C21;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #BD2C21;
        text-align: center;
        line-height: 23px;
        border-radius: 2px;
        margin-right: 10px;
      }

      .name {
        width: 116px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
      }
    }

    .dian {
      display: flex;
      margin-top: 20px;
      height: 52px;

      .dianlogo {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .dianright {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
        width: 160px;

        &:nth-child(2) {
          color: #909090;
        }
      }
    }

    .diandi {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #909090;

      .icon {
        margin-right: 4px;

        svg {
          width: 16px;
          height: 16px;
          font-size: 16px;
          color: #909090;
        }
      }
    }
  }
}