body * {
  margin: 0;
  // font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root a{
  color: #000000;
}
#root .atguigu{
  color: #BD2C21;
}
html, body {
  // width: 100%;
  width: auto !important;
  height: auto !important;
}
.hover{
  transition: all .2s linear;
  cursor: pointer;
} 
.hover:hover{
  box-shadow: 0 15px 30px rgba(0,0,0,.1);
  transform: translate3d(0,-2px,0);
}
.cursor{
  cursor: pointer;
  &:hover{
    opacity: 0.6;
  }
}
.sheng{
  overflow: hidden;/*超出部分隐藏*/
  white-space: nowrap;/*不换行*/
  text-overflow:ellipsis;/*超出部分文字以...显示*/
}
.sheng2{
  overflow : hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
