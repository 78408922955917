.market {
  margin-bottom: 114px;
  .marketTopW {
    width: 100%;
    height: 70px;
    background: #F7F7F7;
    border: 1px solid #EEEEEE;

    .marketTop {
      width: 1400px;
      height: 70px;
      margin: auto;

      .varietyList {
        display: flex;
        height: 70px;
        text-align: center;
        flex-wrap: wrap;
        align-content: center;

        .varietyItem {
          width: 120px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          cursor: pointer;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #000000;
        }

        .active {
          width: 120px;
          height: 40px;
          background: rgba(189, 44, 33, 0.05);
          border: 1px solid #BD2C21;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #BD2C21;
        }
      }
    }
  }
}