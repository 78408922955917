.news {
  width: 100%;

  .list {
    width: 1400px;
    margin: auto;

    .item {
      width: 1400px;
      height: 40px;
      background: #FAFAFA;
      margin: 10px 0;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.breadcrumb {
  width: 100%;
  height: 40px;
  background: #F9F9F9;

  .nei {
    width: 1400px;
    margin: 0 auto;
    height: 100%;

    .ant-breadcrumb {
      height: 40px;
      line-height: 40px;
    }
  }
}