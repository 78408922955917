.caigoudating {
  .tuijian {
    font-size: 24px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #909090;
    text-align: center;
  }

  .biaodan {
    width: 1400px;
    margin: auto;
    margin-top: 23px;
    height: 650px;
    margin-bottom: 50px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .listTable {
      width: 100%;

      .listThead {
        width: 1400px;
        height: 60px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 60px;
    
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          th {
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }

      .listTbody {
        width: 1400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          height: 60px;
    
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          text-align: center;

          .ckxq {
            color: #BD2C21;
            cursor: pointer;
          }
        }
      }
    }

  }

  .fenyeqi .ant-pagination {
    display: flex;
    justify-content: center;
  }
}

.caigouModal {
  .ant-modal-content {
    width: 800px;
    background: #FFFFFF;

    .ant-modal-body {
      padding-top: 10px;
      padding-right: 75px;
    }

    .ant-modal-footer {
      border-top: none;
      text-align: center;
    }
  }

}