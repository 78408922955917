.authentication {
  height: 1480px;
  text-align: center;

  .ding {
    height: 300px;
    background: #B92115;

    .title {
      height: 150px;
      line-height: 150px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }

    .biao {
      width: 1400px;
      padding-bottom: 40px;
      background: #FFFFFF;
      padding-top: 50px;
      box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);
      margin: auto;

      .xuan {
        display: flex;
        align-items: center;
        justify-content: space-around;

        >div {
          width: 400px;
          height: 150px;
          background: #FFFFFF;
          box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);
          display: flex;
          align-items: center;
          padding: 20px;

          >img:nth-child(1) {
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }

          >div:nth-child(2) {
            text-align: left;
            margin-right: auto;

            >div:nth-child(1) {
              font-size: 24px;
              font-family: PingFang;
              font-weight: 500;
              color: #000000;
            }

            >div:nth-child(2) {
              font-size: 16px;
              font-family: PingFang;
              font-weight: 500;
              color: #909090;
            }
          }
        }
      }

      .padding1 {
        padding-top: 50px;

        .marbo10 {
          margin-bottom: 24px;
        }

        .left {
          text-align: right;
          line-height: 40px;

          >span::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
          }
        }
      }
    }
  }
}