#home{
  .youzhi{
    width: 1400px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);
    margin: 70px auto 0;
    padding: 30px 16px;
    .title{
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      font-family: BDZongYi-A001;
      font-weight: normal;
      color: #000000;
    }
    .list{
      display: flex;
      align-items: center;
      margin-top: 28px;
      img{
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
      .item{
        font-size: 14px;
        width: 170px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
        text-align: center;
        div{
          margin-top: 4px;
        }
      }
    }
  }
  .paihang{
    margin:70px auto 60px;
    width: 1400px;
    display: flex;
    justify-content: space-between;
  }
  .freezer{
    width: 100%;
    height: 836px;
    .lengkuimg{
      width: 100%;
      height: 500px;
    }
    .ding{
      width: 1400px;
      margin: 0 auto;
      position: relative;
      .imgname{
        position: absolute;
        width: 210px;
        height: 84px;
        right: 0;
        top: -384px;
      }
      .list{
        padding: 20px 20px;
        width: 100%;
        height: 580px;
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 10px 30px 0px rgba(158, 158, 158, 0.2);
        position: absolute;
        right: 0;
        top: -244px;
        >div{
          width: 670px;
          .name{
            width: 100%;
            height: 96px;
            line-height: 96px;
            text-align: center;
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
          }
          .item{
            width: 100%;
            height: 130px;
            background: #F7F7F7;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .left{
              width: 550px;
              >div:nth-child(1){
                height: 18px;
                font-size: 18px;
                line-height: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
              }
              >div:nth-child(2){
                height: 15px;
                font-size: 14px;
                line-height: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #505050;
                margin-top: 13px;
              }
              >div:nth-child(3){
                height: 24px;
                line-height: 24px;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #BD2C21;
                margin-top: 26px;
              }
            }
          }
        }
      }
    }
    
  }
  .love{
    width: 1400px;
    margin: auto;
    .title{
      width: 100%;
      height: 148px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909090;
      >.hr{
        width: 100px;
        border: 1px solid #909090;
        width: 100px;
        background: #909090;
        margin: 30px;
        position: relative;
      }
      >.hr:nth-child(1)::after{
        content: '';
        position: absolute;
        right: -4px;
        top: -4px;
        width: 8px;
        height: 8px;
        background: #909090;
        border-radius: 50%;
      }
      >.hr:nth-child(3)::after{
        content: '';
        position: absolute;
        left: -4px;
        top: -4px;
        width: 8px;
        height: 8px;
        background: #909090;
        border-radius: 50%;
      }
    }
    .item1{
      width: 100%;
      display: flex;
    flex-flow: row wrap;
      &::after{
        content: '';
        clear: none;
      }
      // display: flex;
      // flex-flow: row wrap;
      // justify-content: space-between;
    }
  }
  .fiexd{
    height: 263px;
    width: 100px;
    padding: 0px 25px;
    position: fixed;
    right: 80px;
    top: 45%;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.3);
    text-align: center;
    >div{
      margin-top: 6px;
      padding: 20px 0 20px 0;
      border-bottom: 1px solid #EEEEEE;
      cursor: pointer;
      &:hover{
        opacity: 0.6;
      }
    }
    >div:nth-child(3){
      border-bottom: none;
      padding-top: 7px;
    }
  }
}
#co{
  min-width: 1400px;
}
