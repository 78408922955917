.my{
  .wei{
    height: 40px;
    background: #F9F9F9;
    line-height: 40px;
    .ant-breadcrumb{
      width: 1400px;
      margin: auto;
      line-height: 40px;
    }
  }
  .width1400{
    width: 1400px;
    margin: auto;
  }
}
