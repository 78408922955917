.logo{
  width: 1400px;
  height: 110px;
  margin:auto;
  line-height: 110px;
  img{
    width: 334px;
    height: 76px;
  }
}
.bgInterior{
  width: 1400px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .form{
    width: 500px;
    height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 0px rgba(33, 7, 3, 0.2);
    opacity: 0.99;
  }
}