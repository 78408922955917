.marketConsult {
  margin: 29px auto;
  width: 1400px;

  .marketConsultWTop {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .marketConsultLeft {
      display: flex;
      justify-content: space-between;
      width: 1070px;
      height: 500px;

      .marketConsultLeftBig {
        position: relative;
        cursor: pointer;
        width: 750px;
        height: 500px;

        img {
          width: 100%;
          height: 100%;
        }

        .hotContent {
          position: absolute;
          bottom: 0;
          padding: 23px;
          .title {
            font-size: 20px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #FFFFFF;
          }

          .time {
            font-size: 14px;
            font-family: MicrosoftYaHeiLight;
            font-weight: 400;
            color: #FFFFFF;
          }

          .content {
            
            font-size: 14px;
            font-family: MicrosoftYaHeiLight;
            font-weight: 400;
            color: #FFFFFF;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .marketConsultLeftBigSmall {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pic {
          width: 300px;
          height: 245px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .marketConsultRight {
      padding: 20px 10px 42px 10px;
      width: 310px;
      height: 500px;
      background: #FAFAFA;

      .new {
        display: inline-block;
        margin-left: 20px;
        margin-bottom: 30px;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #000000;
      }

      .ul {
        padding: 0px;
      }

      ul>li {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 30px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #000000;

        .news {
          width: 216px;
          overflow: hidden;
          display: inline-block;
        }

        .time {
          display: inline-block;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #505050;
        }
      }
    }
  }

  .marketConsultContent {
    display: flex;
    justify-content: space-between;

    .ContentItem {
      cursor: pointer;
      padding: 25px 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      width: 1070px;
      height: 200px;
      background: #FAFAFA;

      .ContentItemLeft {
        width: 150px;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .ContentItemRight {
        width: 856px;
        padding: 17px 0;

        .title {
          margin-bottom: 5px;
          font-size: 20px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #000000;
        }

        .content {
          margin-bottom: 15px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #505050;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .time {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #505050;
        }
      }
    }

    .marketConsultContentRight {
      width: 310px;

      .topPic {
        margin-bottom: 20px;
        width: 310px;
        height: 150px;
        background: #13B5B1;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .bottomContent {
        padding: 20px;
        width: 310px;
        height: 620px;
        background: #FAFAFA;

        .title {
          margin-bottom: 29px;
          font-size: 20px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #000000;
        }

        .bottomContentItem {
          cursor: pointer;
          display: flex;
          justify-content: space-between;

          .pic {
            width: 70px;
            height: 70px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .picRight {
            padding: 7px 0;

            .title {
              margin-bottom: 0px;
              width: 196px;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: #000000;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .time {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: #505050;
            }
          }
        }
      }
    }
  }
}