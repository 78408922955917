.marketAppW {
  width: 690px;
  margin: auto;

  .marketApp {
    .marketTab {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      width: 690px;
      height: 80px;
      background: #FFFFFF;
      border-radius: 10px 10px 0px 0px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 80px;
      letter-spacing: 1px;

      .active {
        border-bottom: 4px solid #D3040F;
        border-radius: 2px;
      }
    }

    .priceTab {
      .ant-tabs {
        width: 100%;

        .ant-tabs-nav {
          .ant-tabs-nav-list {
            width: 654px;
            height: 62px;
            background: #FFFFFF;
            border-radius: 33px;
            border: 1px solid #D3040F;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .ant-tabs-tab {
              width: 218px;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #D3040F;
              line-height: 40px;
              letter-spacing: 1px;

              .ant-tabs-tab-btn {
                margin: auto;
              }
            }

            .ant-tabs-tab-active {
              width: 218px;
              height: 62px;
              background: #D3040F;
              border-radius: 33px;
              border: 1px solid #D3040F;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF !important;
              line-height: 40px;
              letter-spacing: 1px;

              .ant-tabs-tab-btn {
                color: #FFFFFF;
              }
            }
          }

        }

      }
    }

  }
}