.biaoge {
  .tishi {
    padding-left: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #FFDADB;

    span:nth-child(1) {
      text-align: center;
      line-height: 16px;
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      background: #BD2C21;
      color: #FFF;
    }

    span:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      margin-left: 4px;
    }
  }

  .shouhuobiaoge {
    min-height: 372px;
    border: 1px solid #DADBDD;

    .listTable {
      width: 100%;

      .listThead {
        width: 1400px;
        height: 56px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 56px;
          line-height: 56px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
          text-align: center;

          th {
            border-right: 1px solid #DADBDD;
            ;
          }
        }
      }

      .listTbody {
        width: 1400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          height: 60px;
          // line-height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4A4A4A;
          line-height: 20px;

          td {
            text-align: center;
            border-right: 1px solid #DADBDD;

            &:nth-child(2) {
              padding: 0 44px;
            }
          }

          .moren {
            width: 80px;
            height: 30px;
            margin: auto;
            text-align: center;
            line-height: 30px;
            background: rgba(211, 4, 15, 0.3);
            border-radius: 4px;
            border: 1px solid #D3040F;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #D3040F;
          }
        }
      }
    }
  }
}

.xiugaiModal .ant-modal-content{
  width: 800px;
  height: 468px;
  background: #FFFFFF;
  border-radius: 12px;
  .ant-modal-footer{
    border-top: none;
  }
}