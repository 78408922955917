.my{
  width: 100%;
  .top{
    height: 80px;
    background: #B92115;
    .topnei{
      width: 1402px;
      margin: auto;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img{
        width: 240px;
        height: 90px;
      }
      .ant-input-affix-wrapper-lg{
        height: 40px;
        width: 300px;
      }
      .ant-input-group-wrapper{
        width: 100%;
      }
    }
  }
  .body{
    width: 1400px;
    margin: auto;
    display: flex;
    margin-bottom: 160px;
    .left{
      width: 161px;
      height: 700px;
      background: #F7F7F7;
      .item{
        display: block;
        width: 100%;
        height: 36px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        &:hover{
          opacity: 0.6;
        }
      }
      .sty{
        color: #B92115 !important;
      }
    }
    .right{
      flex: 1;
      padding-top: 20px;
      padding-left: 20px;
    }
  }
}