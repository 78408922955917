.safeguard {
  margin: 29px auto 19px;
  width: 1398px;
  height: 475px;

  .safeguardTop{
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
  }
}