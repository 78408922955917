.MarketConsultDetails {
  margin: 40px auto;
  width: 1400px;

  .MarketConsultDetailsTop {
    margin-bottom: 20px;
    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }

    .time {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909090;
    }

  }

  .MarketConsultDetailsContent {
    .pic {
      width: 1400px;
      height: 600px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .content {
      margin-top: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
    }
  }
}