.hometype1 {
  width: 100%;
  min-width: 1400px;
  height: 1050px;
  display: flex;
  justify-content: center;
  align-items: center;

  .dafenlei {
    width: 450px;
    height: 910px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 55px;

    .neiitem {
      height: 35px;
      line-height: 35px;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 18px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0 26px;
      float: left;
      margin: 20px 20px 0 0 ;
      cursor: pointer;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .styitem {
      background: rgba($color: #000000, $alpha: 0.5);
    }
  }

  .right {
    width: 950px;
    height: 910px;
    background-color: #FFFFFF;

    .top {
      width: 100%;
      height: 850px;
      display: flex;
      flex-flow: row wrap;

      .item {
        width: 237px;
        height: 425px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-left: none;
        padding: 20px;
        cursor: pointer;

        &:nth-child(n+3) {
          border-top: none;
          border-bottom: none;
        }

        .img {
          width: 100%;
          height: 200px;
        }

        .jia {
          height: 62px;
          line-height: 62px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #BD2C21;

          span {
            font-size: 24px;
          }
        }

        .you {
          display: flex;
          height: 25px;
          align-items: center;

          .youpin {
            width: 70px;
            height: 25px;
            background: rgba(189, 44, 33, 0.05);
            border: 1px dashed #BD2C21;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #BD2C21;
            text-align: center;
            line-height: 23px;
            border-radius: 2px;
            margin-right: 10px;
          }

          .name {
            width: 116px;
            height: 18px;
            font-size: 18px;
            line-height: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }

        .dian {
          display: flex;
          margin-top: 20px;
          height: 60px;

          .dianlogo {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .dianright {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #505050;
            width: 160px;

            &:nth-child(2) {
              color: #909090;
            }
          }
        }

        .diandi {
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: #909090;

          .icon {
            margin-right: 4px;

            svg {
              width: 16px;
              height: 16px;
              font-size: 16px;
              color: #909090;
            }
          }
        }
      }
    }

    .rightbuttom {
      height: 60px;
      border: 1px solid #EEEEEE;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}