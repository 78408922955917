.shopRecommend {
  .itemList {
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 20px;
      width: 280px;
      height: 340px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      &:hover{
        background: #FFFFFF;
        box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.3);
        cursor: pointer;
        margin-bottom: 0 10px 10px 0;
      }
      .itempic {
        width: 240px;
        height: 240px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .iteminfo {
        text-align: center;
        padding-top: 14px;
        .itemname {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #000000;
        }

        .price {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: #BD2C21;
        }
      }
    }
  }
}