.gongyingxiangqing {
  .gongyinginfo {
    width: 1400px;
    height: 360px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    margin: auto;
    margin-bottom: 40px;

    .gongyinginfotop {
      width: 1400px;
      height: 40px;
      line-height: 40px;
      padding-left: 30px;
      background: #FAFAFA;
      border: 1px solid #EEEEEE;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }

    .gongyinginfobody {
      padding-left: 30px;
      padding-bottom: 24px;
      width: 1400px;
      border: 1px solid #EEEEEE;
      min-height: 320px;
      background: #FFFFFF;

      .body-left {
        float: left;
        width: 1000px;
        height: 100%;
        padding-top: 10px;

        .gongying-item {
          width: 500px;
          float: left;

          &:nth-child(8) {
            float: none;
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;

            span:nth-child(1) {
              float: left;
            }

            span:nth-child(2) {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              display: -webkit-inline-box;
            }
          }

          .itemtext-lf {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #505050;
          }

          .itemtext-lr {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }

        .gongying-item-pic {
          width: 100%;
          height: 100px;
          float: right;
          img {
            width: 100px;
            height: 100px;
            margin-right: 10px;
          }
        }
      }

      .body-right {
        margin-right: 60px;
        padding-top: 70px;
        float: right;

        // :nth-child(1) {
        //   width: 150px;
        //   height: 50px;
        //   margin-bottom: 10px;
        //   text-align: center;
        //   line-height: 45px;
        //   background: #BD2C21;
        //   border-radius: 25px;
        //   font-size: 20px;
        //   font-family: Microsoft YaHei;
        //   font-weight: 400;
        //   color: #FFFFFF;
        // }
        .ant-btn,.liaoyiliao{
          margin-bottom: 20px;
          width: 150px;
          height: 50px;
          text-align: center;
          line-height: 45px;
          background: rgba(189, 44, 33, 0.05);
          border: 1px solid #BD2C21;
          border-radius: 25px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #BD2C21;
        }
      }
    }
  }

  .gongyingreninfo {
    margin: auto;
    margin-bottom: 50px;
    width: 1400px;
    height: 180px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

    .gongyingrentop {
      padding-left: 29px;
      width: 1400px;
      height: 40px;
      line-height: 40px;
      background: #FAFAFA;
      border: 1px solid #EEEEEE;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }

    .caogourenbody {
      width: 1400px;
      height: 140px;
      padding-left: 22px;
      padding-top: 20px;
      display: flex;

      .gongyingrentouxiang {
        margin-right: 10px;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }

        .gongyingrenxiangqing-item {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;


          span:nth-child(1) {
            color: #505050;
          }
        }

        .gongyingrenxiangqing-item:nth-child(1) {
          span:nth-child(3) {
            color: #BD2C21;
          }
        }

        .gongyingrenxiangqing-item:nth-child(1),
        .gongyingrenxiangqing-item:nth-child(3) {
          span:nth-child(2) {
            color: #000000;
          }
        }
      }
    }
  }
}