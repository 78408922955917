.rechargeAmount {
  .rechargeAmountInput {
    padding-top: 20px;

    input {
      margin-right: 10px;
      text-align: center;
      width: 263px;
      height: 50px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
    }

    .yuan {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #010101;
      line-height: 20px;
    }

    .tiShi {
      width: 263px;
      text-align: center;
      margin-top: 10px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #B92115;
      line-height: 20px;
    }
  }

  .modeOfPayment {
    padding-left: 20px;
    margin-top: 20px;

    .modeOfPaymentTitle {
      margin-bottom: 19px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
    }

    .paymentList {
      display: flex;

      .paymentItem {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 45px;
        width: 198px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;

        .paymentName {
          margin-left: 31px;
        }
      }

      #active {
        border: 1px solid #B92115;
        background: url(../../../../static/img/youxiabiao.png) no-repeat;
        background-position: right bottom;
      }
    }

  }

  .payBtn {
    margin: 48px 0 0 444px;
    width: 151px;

    .paySucceed {
      p {
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }

    .ant-btn {
      width: 151px;
      height: 50px;
      background: #B92115;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }

    .paymentCode {
      width: 180px;
      text-align: center;

      .xuzf {
        margin-bottom: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;

        .price {
          color: #B92115;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
      }

      .QRcode {
        width: 180px;
        height: 180px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .paymentCodeP {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #343434;
        line-height: 40px;
      }
    }


  }
}