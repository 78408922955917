.myhangqing {
  width: 100%;
  height: 800px;
  background: #FFFFFF;
  border: 1px solid #E9E9E9;
  .hangqingitem{
    border-bottom: 1px solid #E9E9E9;
    padding-left: 30px;
    padding-right: 118px;
    padding: 20px;
  }
  .biaoti {
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4A4A4A;
    line-height: 22px;
    letter-spacing: 1px;
    border-bottom: 1px solid #E9E9E9;
  }

  .hangqingList {
    padding-left: 22px;
    padding-right: 30px;

    .title {
      margin-bottom: 10px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }

    .content {
      margin-bottom: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 24px;
    }

    .info {
      margin-bottom: 10px;
      .name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 22px;
      }

      .time {
        margin-left: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 22px;
      }
    }

    .itembottom {
      margin-bottom: 20px;
      .ant-btn:nth-child(1) {
        padding: 0;
        width: 40px;
        height: 22px;
        background: #F5F5F5;
        border-radius: 4px;
        border: 1px solid #D9D9D9;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }

      .ant-btn:nth-child(2) {
        padding: 0;
        margin-left: 15px;
        width: 64px;
        height: 22px;
        border-radius: 4px;
        border: 1px solid #D3040F;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D3040F;
        line-height: 22px;
      }

    }
  }
  .ant-pagination{
    text-align: right;
    margin-top: 30px;
  }
}