.financialSubmitInfo {
  .listTable {
    table-layout: fixed;
  }

  .examineIng {
    margin: auto;
    width: 1400px;

    .examineIngPic {
      margin: 63px auto 81px;
      width: 212px;
      height: 212px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .hint {
      margin-bottom: 82px;
      text-align: center;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #272727;
    }
  }

  .callService {
    cursor: pointer;
    margin: auto;
    text-align: center;
    line-height: 100px;
    width: 600px;
    height: 100px;
    background: #D5B16E;
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
  }

  .warmPrompt {
    margin: 46px 0 77px;
    display: flex;
    font-size: 14px;
    height: 48px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
}