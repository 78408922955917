.details{

  .cxBtn{
    height: 30px;
    width: 80px;
  }
  .towai2{
    height: 70px;
    background: #F7F7F7;
    border: 1px solid #EEEEEE;
    .nei{
      width: 1400px;
      margin: auto;
      height: 70px;
      display: flex;
      align-items: center;
      >div{
        width: 120px;
        height: 40px;
        border: 1px solid #E5E5E5;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      >.sta{
        background: rgba(189, 44, 33, 0.05);
        border: 1px solid #BD2C21;
        color: #BD2C21;
      }
    }
  }
}
.towai{
  .topnei{
    .cxBtn{
      cursor: pointer;
      width: 80px;
      height: 30px;
    }
  }
}
.dianpuxixi{
  width: 1400px;
  margin: auto;
  padding-bottom: 180px;
  .on1{
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    >div:nth-child(1){
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding-bottom: 6px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        left: -12px;
        top: 48%;
        width: 4px;
        height: 15px;
        background: #BD2C21;
        transform: translate(-50%, -50%);
      }
    }
    >div:nth-child(2){
      display: flex;
      justify-content: space-between;
      >div{
        width: 506px;
      }
      >div:nth-child(1){
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
        line-height: 24px;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          right: -36%;
          top: 0;
          width: 1px;
          height: 87px;
          background: #DCDCDC;
        }
      }
      >div:nth-child(2){
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
        line-height: 26px;
      }
    }
  }
  .on2{
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    >div:nth-child(1){
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding-bottom: 6px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        left: -12px;
        top: 48%;
        width: 4px;
        height: 15px;
        background: #BD2C21;
        transform: translate(-50%, -50%);
      }
    }
    >div:nth-child(2){
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #505050;
      line-height: 34px;
    }
  }
  .on3{
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    >div:nth-child(1){
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding-bottom: 6px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        left: -12px;
        top: 48%;
        width: 4px;
        height: 15px;
        background: #BD2C21;
        transform: translate(-50%, -50%);
      }
    }
    >div:nth-child(2){
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      .ant-image{
        margin-right: 20px;
      }
      .img{
        width: 170px;
        height: 170px;
        margin-right: 20px;
      }
    }
    .a{
      text-align: center;
    }
  }
  .on4{
    >div:nth-child(2){
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    >div:nth-child(n+2){
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #505050;
      line-height: 36px;
    }
    >div:nth-child(4){
      >div{
        display: inline-block;
        width: 90px;
        height: 30px;
        border: 1px solid #BD2C21;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        color: #BD2C21;
      }
     
    }
  }
}
