.openVip {
  .bannerTopW {
    overflow: hidden;
    width: 100%;
    height: 600px;
    background: url(../../../static/img/bannerTop.png) no-repeat;
    background-size: cover;


    .bannerTop {
      margin: auto;
      width: 1400px;
      height: 600px;


      .headline {
        display: flex;
        justify-content: center;
        margin-top: 146px;

        .titleH1 {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #DFB174;
        }

        .headlineVPic {
          line-height: 100px;
          margin-right: 17px;
        }
      }

      .price {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 70px 0;

        .priceLeft,
        .priceRight {
          width: 77px;
          height: 1px;
          background: #DFE3E6;
        }

        .priceMiddle {
          margin: 0 20px;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .openBtn {
        display: flex;
        justify-content: center;

        .ant-btn {
          width: 280px;
          height: 60px;
          background: #D7AA6A;
          border-radius: 30px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
  }

  .bannerBottomW {
    overflow: hidden;
    width: 100%;
    height: 800px;
    background: url(../../../static/img/bannerBottom.png) no-repeat;
    background-size: cover;

    .bannerBottom {
      margin: auto;
      width: 1400px;
      height: 800px;

      .VIPRightTitle {
        margin: 79px 0 126px;
        display: flex;
        justify-content: center;
        align-items: center;

        .VIPRightTitleRight,
        .VIPRightTitleLeft {
          margin: 0 50px;
          width: 361px;
          height: 1px;
          background: #DFB174;
        }

        .VIPRightTitleMiddle {

          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #DFB174;
        }
      }

      .VIPRightsContentList {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .VIPRightsContentItem {
          width: 400px;
          height: 210px;
          text-align: center;
          background: #8C663B;

          .VIPRightsContentText {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 64px 0;
            height: 100%;

            .VIPRightsContentItemTop {
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }

            .VIPRightsContentItemBottom {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
          }

        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}