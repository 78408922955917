.myfootprint {
  .footprintTop {
    width: 1220px;
    height: 50px;
    padding-left: 20px;

    background: #F6F7F8;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4A4A4A;
    line-height: 50px;
    letter-spacing: 1px;
  }

  .footprintTime {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .time {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 42px;
    }

    .baby {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
    }
  }

  .shopList {
    display: flex;  
    flex-wrap: wrap;
    .shopItem {
      cursor: pointer;
      margin-right: 29px;
      width: 283px;
      height: 347px;
      background: #FFFFFF;
      border: 1px solid #E9E9E9;
      padding: 16px;
      margin-top: 20px;
      &:nth-child(4n+4){
        margin-right: 0;
      }
      &:hover{
        margin-top: 4px;
      }
      .itemPic{
        margin-bottom: 13px;
        img {
          width: 250px;
          height: 250px;
        }
      }
      .itemInfo{
        .price {
          margin-bottom: 6px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #D3040F;
          line-height: 22px;
        }
  
        .content {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9A9A9A;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

    }
  }
}