.priceexpectation {
  padding: 0 20px;
  width: 1400px;
  height: 800px;
  margin: 20px auto 0;
  background: #FFFFFF;
  box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

  .priceexpectationTop {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #EEEEEE;

    .left {
      display: flex;
      line-height: 30px;

      .red {
        width: 8px;
        height: 30px;
        background: #E64F38;
        margin-right: 15px;
      }

      .title {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  .content {
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #505050;
  }

  .priceexpectationList {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .voteList {
    display: flex;
    justify-content: space-around;

    .tp {
      cursor: pointer;
      line-height: 60px;
      text-align: center;
      width: 200px;
      height: 60px;
      background: #E64F38;
      font-size: 28px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #FFFFFF;
    }

    .vote {
      cursor: pointer;
      line-height: 60px;
      text-align: center;
      width: 200px;
      height: 60px;
      background: RGBA(252, 237, 235);
      // opacity: 0.1;
      font-size: 28px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #E64F38;
    }
  }
}