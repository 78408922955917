.confirmorder {
  .body {
    width: 1400px;
    margin: auto;

    .dizi {
      width: 1400px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin: 20px 0;
      padding: 0 20px;

      .top {
        display: flex;
        width: 100%;
        height: 48px;
        justify-content: space-between;
        align-items: center;

        .guanli {
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }
      }

      .ant-radio-group {
        width: 100%;

        span.ant-radio+* {
          padding-right: 8px;
          padding-left: 8px;
          width: 100%;
        }
      }

      .li {
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;

        .di {
          width: 22px;
        }

        .nei {
          margin-left: 10px;

          &>span {
            margin-right: 16px;

            &>span {
              margin-right: 6px;
            }
          }
        }

        .xiugai {
          color: #B92115;

          &:hover {
            opacity: 0.6;
          }
        }

        .flex1 {
          flex: 1;
        }

        .moren:hover {
          opacity: 0.6;
        }
      }
    }

    .list {
      width: 1400px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-bottom: 20px;

      .tou {
        height: 50px;
        background: #F9F9F9;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        align-items: center;

        >span {
          margin-left: 10px;
        }
      }

      .biao {
        padding: 20px 20px 25px;

        .tu {
          width: 60px;
          height: 60px;
        }

        .bodi {
          display: flex;
          justify-content: space-between;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #EEEEEE;
          border-top: none;
          padding: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;

          .left {
            display: flex;
            width: 500px;

            >div {
              width: 110px;
            }
          }

          .right {
            width: 500px;
            display: flex;

            .on1 {
              width: 200px;
              text-align: right;
            }

            .on2 {
              width: 300px;
              text-align: right;
              color: #C33529;
            }
          }
        }

        .di {
          height: 60px;
          background: rgba(222, 50, 50, 0.05);
          border: 1px solid #EEEEEE;
          border-top: none;
          line-height: 60px;
          text-align: right;
          padding: 0 20px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;

          >span:nth-child(2) {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #C33529;
          }
        }
      }

    }

    .jie {
      width: 100%;
      height: 150px;
      margin-bottom: 20px;
      background: rgba(242, 73, 33, 0.05);
      border: 1px solid #F24921;
      text-align: right;
      padding: 20px 40px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;

      >div:nth-child(1) {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #010101;

        >span:nth-child(2) {
          font-size: 32px;
          color: #DF3331;
        }
      }

      >div:nth-child(2) {
        >span {
          margin-left: 6px;
        }
      }
    }

    .anniu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 60px;
      margin-bottom: 80px;

      >button {
        margin-left: 20px;
      }
    }
  }
}
textarea{
  resize: none;
}