.mygongying {
  width: 100%;
  height: 800px;
  background: #FFFFFF;
  border: 1px solid #E9E9E9;

  .ant-pagination {
    margin-top: 30px;
  }

  .biaoti {
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4A4A4A;
    line-height: 22px;
    letter-spacing: 1px;
  }

  .gongying {
    padding-left: 30px;
    padding-right: 26px;

    .gongyingtab {
      margin-bottom: 20px;
      display: flex;
      text-align: center;
      line-height: 42px;
      justify-content: center;

      .tabitem {
        width: 388px;
        height: 42px;
        background: #EFEFEF;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
      }

      .active {
        background: #D3040F;
        color: #FFFFFF;
      }

    }

    .listTable {
      width: 100%;

      .listThead {
        width: 1400px;
        height: 54px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 54px;
          line-height: 54px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          th {
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }

      .listTbody {
        width: 1400px;
        // height: 480px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          height: 119px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          td {
            text-align: center;

            .ant-btn {
              border: none;
            }
          }

        }
      }
    }
  }

  .ant-pagination {
    text-align: right;
    padding-right: 70px;
  }
}



.bianjiModal {
  .ant-modal-content {
    width: 800px;
    height: 700px;
    background: #FFFFFF;

    .ant-modal-footer {
      text-align: center;

      .ant-btn {
        width: 120px;
        height: 40px;
        background: #BD2C21;
      }
    }
  }

}