.breadcrumbW {
  height: 40px;
  background: #F9F9F9;

  .breadcrumb {
    width: 1400px;
    margin: auto;

    .ant-breadcrumb {
      height: 40px;
      line-height: 40px;
    }
  }
}

.futurestradingDetails {
  width: 1400px;
  height: 1000px;
  margin: auto;

  .futurestradingDetailsTop {
    display: flex;
    margin: 20px 0 15px;

    .contract {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #000000;

      span {
        margin-right: 9px;
      }
    }

    .search {
      cursor: pointer;
      width: 55px;
      height: 35px;
      background: #B92115;
      background-image: url('../../../../static/img/search.png');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .futurestradingDetailsBottom {
    display: flex;

    .futurestradingDetailsLeft {
      margin-right: 20px;
      padding-top: 35px;
      width: 836px;
      height: 872px;
      background: #FFFFFF;
      box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

      .timeList {
        display: flex;
        justify-content: space-between;
        width: 620px;

        #active {
          background: #E64F38;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }

        .timeItem {
          text-align: center;
          line-height: 40px;
          width: 100px;
          height: 40px;
          background: rgba(230, 79, 56, 0.05);
          border: 1px solid #E64F38;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #E64F38;
        }
      }
    }

    .futurestradingDetailsRight {
      .futurestradingDetailsRightTop {
        padding: 20px;
        width: 544px;
        height: 333px;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

        .title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #DB1313;
        }

        .content {
          margin: 39px 0 49px;
          display: flex;
          flex-wrap: wrap;

          .contentItem {
            padding: 0 26px 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 252px;
            height: 40px;
            background: #FAFAFA;
            border: 1px solid #EEEEEE;
          }
        }

        .buyBtn {
          margin: auto;
          cursor: pointer;
          text-align: center;
          line-height: 60px;
          width: 180px;
          height: 60px;
          background: #E64F38;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .futurestradingDetailsRightBottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        width: 544px;
        height: 519px;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

        .futurestradingDetailsRightBottomTable {
          padding: 20px;

          .listTable {
            width: 100%;

            .listThead {
              width: 1400px;
              height: 40px;
              background: #FAFAFA;
              border: 1px solid #EEEEEE;

              .listTr {
                table-layout: fixed;
                height: 40px;
                border-top: 1px solid #EEEEEE;
                border-left: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #505050;

                th {
                  width: 48px;
                  text-align: center;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #505050;
                }

                .tabOperationTh {
                  text-align: end;
                  padding-right: 20px;
                }
              }
            }

            .listTbody {
              width: 1400px;
              height: 40px;
              background: #FAFAFA;
              border: 1px solid #F7F7F7;

              .TbodyTr:hover {
                background: rgba(189, 44, 33, 0.05);

                td {
                  color: #BD2C21;
                }

              }

              .TbodyTr {
                table-layout: fixed;
                height: 40px;
                border-top: 1px solid #EEEEEE;
                border-left: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #000000;

                td {
                  text-align: center;
                  font-size: 16px;
                  font-family: Adobe Heiti Std;
                  font-weight: normal;
                  color: #000000;
                }

                .tabOperationTd {
                  height: 40px;
                  padding-right: 12px;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;

                  span {
                    cursor: pointer;
                    font-size: 16px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #BD2C21;
                  }
                }
              }
            }
          }
        }

        .paging {
          text-align: center;
          height: 50px;
          background: #FAFAFA;
          border: 1px solid #EEEEEE;

          .ant-pagination {
            line-height: 50px;
          }
        }
      }
    }
  }
}

.detailsBalanceModal {
  width: 1000px !important;
  .ant-modal-content {
    width: 1000px;
    .addOrderModalContent {
      display: flex;
      flex-direction: column;

      .listTable {
        width: 100%;

        .listThead {
          // width: 1400px;
          height: 40px;
          background: #FAFAFA;
          border: 1px solid #EEEEEE;

          .listTr {
            table-layout: fixed;
            height: 40px;
            border-top: 1px solid #EEEEEE;
            border-left: 1px solid #EEEEEE;
            border-right: 1px solid #EEEEEE;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #505050;

            th {
              // width: 48px;
              text-align: center;
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #505050;
            }
          }
        }

        .listTbody {
          // width: 1400px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #F7F7F7;

          .TbodyTr:hover {
            background: rgba(189, 44, 33, 0.05);

            td {
              color: #BD2C21;
            }

          }

          .TbodyTr {
            table-layout: fixed;
            height: 40px;
            border-top: 1px solid #EEEEEE;
            border-left: 1px solid #EEEEEE;
            border-right: 1px solid #EEEEEE;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;

            td {
              text-align: center;
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #000000;
            }
          }
        }
      }

      .summation {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .summationLeft {

          .goodsPrice {
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #363636;

            .goodsPriceName {
              margin-right: 73px;
            }
          }

          .rent {
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #363636;

            .rentName {
              margin-right: 113px;
            }
          }
        }

        .summationRight {
          display: flex;
          line-height: 22px;
          align-items: center;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #363636;

          .Price {
            height: 20px;
            font-size: 24px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #363636;
          }
        }
      }


      // .balanceModalContentTop {
      //   display: flex;
      //   justify-content: space-around;
      //   height: 72px;
      //   align-items: center;
      // }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 50px auto 0;
        width: 203px;
        height: 41px;
        background: #C5353D;
        border: 1px solid #BD2C21;
        border-radius: 20px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 20px;
      }

      .addOrderModalContentBottom {
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin: 50px auto 0;
        width: 203px;
        height: 41px;
        background: #C5353D;
        border: 1px solid #BD2C21;
        border-radius: 20px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
      }
    }
    .ant-modal-footer {
      border-top: none;
    }
  }
}

.successfullyModal {
  .ant-modal-content {
    .ant-modal-body {
      height: 322px;

      p {
        margin-top: 64px;
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }

      .successfullyModalBtn {
        display: flex;
        justify-content: space-around;
        margin-top: 93px;

        .ant-btn {
          width: 150px;
          height: 40px;
          background: #BD2C21;
          border: 1px solid #BD2C21;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
        }

        // .cancel {
        //   cursor: pointer;
        //   text-align: center;
        //   line-height: 40px;
        //   width: 150px;
        //   height: 40px;
        //   background: #FFFFFF;
        //   border: 1px solid #B5B5B5;
        //   border-radius: 20px;
        //   font-size: 16px;
        //   font-family: PingFang SC;
        //   font-weight: 400;
        //   color: #000000;
        // }

        // .topUp {
        //   cursor: pointer;
        //   text-align: center;
        //   line-height: 40px;
        //   width: 150px;
        //   height: 40px;
        //   background: #FFFFFF;
        //   border: 1px solid #BD2C21;
        //   border-radius: 20px;
        //   font-size: 16px;
        //   font-family: PingFang SC;
        //   font-weight: 400;
        //   color: #BD2C21;
        // }
      }
    }

    .ant-modal-footer {
      border-top: none;
    }
  }
}