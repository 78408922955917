.withdraw {
  padding: 20px;

  .withdrawTop {
    margin-bottom: 50px;

    .withdrawTopUp {
      margin-bottom: 22px;
      display: flex;
      align-items: center;

      .usablePrice {
        margin-right: 20px;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #010101;

        .price {
          color: #B92115;
        }
      }

      .lookFinancialdetails {
        cursor: pointer;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #B92115;
      }

    }

    .withdrawTopUserName {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .withdrawBottom {
    .xiazaiApp {
      margin-top: 57px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #000000;
      img{
        height: 200px;
        width: 200px;
      }
    }

    .ant-form {
      .ant-row {
        .ant-form-item-label {
          width: 83px;

          label {
            height: 50px;
          }
        }
      }

      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input {
              margin-right: 20px;
              width: 400px;
              height: 50px;
              background: #F9F9F9;
              border: 1px solid #EEEEEE;
            }

            .tips {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }

            .getPhoneCode,
            .forgetPassword {
              cursor: pointer;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              text-decoration: underline;
              color: #0655CE;
            }

            .detectionBtn {
              margin-right: 20px;
              width: 71px;
              height: 34px;
              background: #B92115;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }

            .withdrawBtn {
              margin-top: 75px;
              display: flex;
              justify-content: center;

              .ant-btn {
                width: 104px;
                height: 50px;
                background: #B92115;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}