#Footer {
  width: 100%;
  min-width: 1420px;
  height: 150px;
  background: #F9F9F9;
  text-align: center;
  padding-top: 20px;

  div {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #505050;
    line-height: 28px;

    span {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #505050;
    }

    span+span {
      margin-left: 34px;
    }
  }
}