.priceexpectationapp {
  .priceexpectatio {
    width: 100%;
    margin: 20px auto 0;

    .timeTab {
      display: flex;
      justify-content: space-around;

      .active {

        width: 130px;
        height: 52px;
        background: rgba(211, 4, 15, 0.3) !important;
        border-radius: 26px;
        border: 1px solid #D3040F;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D3040F !important;
        letter-spacing: 1px;
      }

      .day {
        width: 130px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background: #E9E9E9;
        border-radius: 26px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        letter-spacing: 1px;
      }
    }

    .info {
      margin: 35px auto 0;
      padding: 20px;
      width: 650px;
      height: 230px;
      background: #EFF8FF;
      border-radius: 10px;

      .infoItemList {
        line-height: 56px;
        display: flex;
        justify-content: space-around;

        .infoItem {
          .data {
            text-align: center;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5C5C5C;
            letter-spacing: 1px;
          }

          .price {
            text-align: center;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #F16100;
            letter-spacing: 1px;
          }

          .time {
            text-align: center;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 37px;
            letter-spacing: 1px;
          }
        }
      }

      .infoBottom {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        .updateTime {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9A9A9A;
          line-height: 33px;
        }

        .unit {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9A9A9A;
          line-height: 33px;
          letter-spacing: 1px;
        }
      }
    }

    .priceexpectationList {
      margin-top: 50px;
      width: 690px;
      display: flex;
      justify-content: space-evenly;

      .tpItem {
        width: 203px;
        height: 241px;
        border-radius: 20px;
        border: 1px solid #E4EEFC;
      }
    }

    .voteList {
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-around;

      .tp {
        cursor: pointer;
        width: 130px;
        height: 53px;
        line-height: 53px;
        text-align: center;
        background: #E64F38;
        border-radius: 27px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFF;
        letter-spacing: 1px;
      }

      .vote {
        cursor: pointer;
        line-height: 53px;
        text-align: center;
        width: 130px;
        height: 53px;
        background: RGBA(252, 237, 235);
        border-radius: 27px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E64F38;
        letter-spacing: 1px;
      }
    }

    .content {
      text-align: center;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9A9A9A;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
}