.pricetrend {
  margin: 20px auto 0;
  padding: 0 20px;
  width: 1400px;
  height: 800px;
  background: #FFFFFF;
  box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

  .pricetrendTop {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #EEEEEE;

    .left {
      display: flex;
      line-height: 30px;

      .red {
        width: 8px;
        height: 30px;
        background: #E64F38;
        margin-right: 15px;
      }

      .title {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #000000;
      }

      .ant-select {
        margin-left: 33px;
        .ant-select-selector {
          width: 210px;
          height: 45px;

          .ant-select-selection-item {
            width: 100%;
            height: 100%;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 43px;
          }
          .ant-select-selection-search{
            .ant-select-selection-search-input{
              height: 100%;
              line-height: 43px;
            }
          }
        }
      }
    }
  }

  .xinxi {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;

    .xinxiitem {
      width: 670px;
      height: 150px;
      background: #F7F7F7;

      .data {
        margin-top: 20px;
        text-align: center;

        span {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
        }
      }

      .price {
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #E64F38;

        span {
          font-size: 24px;
        }
      }

      .growth {
        margin-top: 10px;
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
      }
    }
  }

  .content {
    margin-top: 22px;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #505050;
  }
}