#header {
  width: 1400px;
  height: 160px;
  margin: auto;
  display: flex;
  flex-direction: column;
  .homeToplo{
    display: flex;
  }
  .hometop {
    width: 1019px;
  }

  >div {
    flex: 1;
  }

  .login {
    width: 334px;
    height: 76px;
    margin-right: 47px;
  }

  .ant-input-group {
    font-size: 16px !important;

    .ant-input {
      height: 50px;
      width: 440px;
      font-size: 16px;
    }

    .ant-input-group-addon .ant-select {
      width: 160px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      text-align: left;
    }

    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 50px;
      align-items: center;
    }

    .anticon svg {
      width: 18px;
      height: 20px;
    }

    .ant-select-arrow {
      top: 45%;
      width: 18px;
      height: 20px;
    }
  }

  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 50px;
    width: 60px;
  }

  .flex1 {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
  }

  .svg {
    .anticon svg {
      font-size: 40px;
    }
  }

  .searchright1 {
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    .one {
      margin-left: 20px;

      >div:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        color: #505050;
      }

      >div:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: #C7C7C7;
      }
    }
  }

  .list {
    width: 1019px;
    height: 32px;
    display: flex;
    align-items: center;

    >div {
      width: 157px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909090;
      margin-right: 40px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &>div:hover {
      opacity: 0.6;
    }
  }

  .tab {
    width: 1080px;
    height: 56px;
    display: flex;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;

    &>.sta {
      color: #B92115;
    }

    &>div {
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

}