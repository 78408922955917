.intie {
  .tips {
    padding: 20px 0 40px 21px;
    font-size: 16px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #909090;
    line-height: 20px
  }

  .intieTable {
    width: 100%;

    .listTable {
      width: 100%;
      text-align: center;

      .listThead {
        .listTr {
          height: 50px;
          background: #F9F9F9;
          border: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;
        }
      }

      .listTbody {
        background: #F9F9F9;
        border: 1px solid #EEEEEE;

        .TbodyTr {
          height: 50px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;

          .delIntie {
            cursor: pointer;
            color: #B92115;
          }
        }
      }
    }
  }
}

.delIntieModal {
  .ant-modal-content {
    width: 600px;

    .ant-modal-body {
      height: 180px;

      p {
        padding-top: 40px;
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }

    .ant-modal-footer {
      padding: 0px 99px 74px 135px;
      border: none;
      text-align: center;

      .ant-btn {
        width: 150px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #B5B5B5;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      .ant-btn-primary {
        width: 150px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #BD2C21;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #BD2C21;
      }
    }
  }
}

.intieModal {
  width: 800px !important;

  .ant-modal-content {
    width: 800px;

    .ant-modal-body {
      padding: 10px 13px 0 14px;
      height: 550px;

      .ant-form {
        .ant-form-item {
          height: 40px;
          margin-bottom: 20px;

          .ant-form-item-label {
            height: 40px;

            label {
              height: 40px;
            }
          }

          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .tips {
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  text-decoration: underline;
                  color: #0655CE;
                  line-height: 20px;
                }
                .forgetPassword{
                  cursor: pointer;
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  text-decoration: underline;
                  color: #0655CE;
                  line-height: 20px;
                }
                .ant-input {
                  margin-right: 12px;
                  width: 400px;
                  height: 40px;
                  background: #FAFAFA;
                  border: 1px solid #EEEEEE;
                }

                .intieBtn {
                  margin-top: 30px;
                  display: flex;
                  justify-content: center;

                  .ant-btn {
                    width: 120px;
                    height: 40px;
                    background: #BD2C21;
                    font-size: 16px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #FFFFFF;
                    line-height: 20px;

                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      border: none;
    }
  }
}