.pingjiaxiangqing {
  margin: auto;
  margin-top: 62px;
  margin-bottom: 100px;
  padding: 20px 30px;
  width: 1400px;
  height: 308px;
  border: 1px solid #E9E9E9;

  .pingluninfo {
    .info-li {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }

  .pic {
    margin-left: 70px;
    margin-top: 20px;

    img {
      width: 78px;
      height: 78px;
      margin-right: 20px;
    }
  }
}