.biaodan {
  margin-top: 20px;

  .citydiv {
    margin-bottom: 24px;
    margin-left: 24px;

    .city {
      width: 439px;
    }
  }

  .xiangxi {
    margin-left: 24px;

    span {
      // display: inline-block;
      float: left;
    }

    textarea {
      resize: none;
      border: 1px solid #d9d9d9;
      padding: 5px 12px;
    }
  }

  .ant-form {
    .ant-form-item {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}