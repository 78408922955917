.myyijian {
  .yijian {
    padding-top: 20px;
    padding-left: 20px;
    width: 1220px;
    height: 679px;
    background: #F6F7F8;

    .miaoshu {
      display: flex;

      .textarea {
        padding: 20px;
        resize: none;
        width: 1060px;
        height: 429px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E3E3E3;
      }
    }

    .phone {
      margin-top: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .ipt {
        width: 236px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E3E3E3;
      }
    }

    .ant-btn {
      margin-left: 70px;
      margin-top: 50px;
      width: 183px;
      height: 51px;
      background: #D3040F;
      border-radius: 4px;
    }
  }
}