.mypingjia {
  width: 100%;
  min-height: 572px;

  .mypingjiaitem {
    width: 100%;
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid #EEEEEE;

    .itemtitle {
      .wen {
        display: inline-block;
        margin-right: 11px;
        width: 25px;
        height: 25px;
        background: #DA1D0F;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        line-height: 25px;
      }

      .titletext {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }

    .iteminfo {
      padding-left: 36px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;

      .infoleft {
        .infolefttop {
          margin-top: 10px;
          margin-bottom: 19px;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 11px;
          }

          .name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }
        }

        .infopingjia {
          width: 1120px;
          display: flex;
          justify-content: space-between;

          .pingjia {
            margin-bottom: 19px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #000000;
          }

          .shanchu {
            cursor: pointer;
            font-size: 12px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #909090;
          }
        }


        .infoleftbottom {
          .liulan {
            display: inline-block;
            width: 171px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }

          .shijian {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }
        }
      }
    }
  }
}