.caigouoffer {
  width: 1400px;
  margin: auto;
  margin-top: 20px;

  .offertop {
    padding-top: 10px;
    padding: 10px 30px 0;
    width: 100%;
    height: 85px;
    background: #F7F8FB;
    border-radius: 4px;

    .offertopname {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .offertopzhong {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #D3040F;
      line-height: 20px;
    }

    .offertoppinzhi {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9A9A9A;
      line-height: 17px;
    }
  }

  .offerlist {
    .offeritem {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 23px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EEEEEE;
      .itemleft {
        .xinxitop {
          display: flex;

          .itempic {
            width: 144px;
            height: 81px;
            border-radius: 12px;
            margin-right: 15px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 12px;
            }
          }

          .iteminfo {
            .name {
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #4A4A4A;
              line-height: 22px;
              letter-spacing: 1px;
            }

            .conten {
              margin-bottom: 18px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9A9A9A;
              line-height: 20px;
              letter-spacing: 1px;
            }

            .dizhi {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9A9A9A;
              line-height: 20px;
              letter-spacing: 1px;
            }
          }
        }

        .xinxibottom {
          display: flex;
          margin-top: 20px;

          .tou {
            margin-right: 6px;

            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }
          }

          .maijia {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }


      }

      .itemright {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;

        .jiage {
          text-align: right;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #D3040F;
          line-height: 22px;
        }

        .itemyouxia {
          display: flex;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          letter-spacing: 1px;

          .ant-btn {
            width: 96px;
            height: 42px;
            background: #D3040F;
            border-radius: 20px;
            color: #FFFFFF;
          }

          .liaoyiliao {
            margin-left: 20px;
            width: 96px;
            height: 42px;
            text-align: center;
            line-height: 42px;
            background: #F47702;
            border-radius: 21px;
          }
        }
      }
    }
  }
}