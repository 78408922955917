.examine {
  width: 100%;
  height: 1020px;
  position: relative;

  .hong {
    height: 300px;
    background: #B92115;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 140px;

    .steps {
      width: 1000px;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon,
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: #ffffff;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
      background: #ffffff;
      width: 14px;
      height: 14px;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after,
    .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
      background-color: #ffffff;
    }

    .ant-steps-dot .ant-steps-item-tail {
      top: 5px;
    }

    .ant-steps-icon-dot {
      width: 14px;
      height: 14px;
    }

    .ant-steps-item-tail::after,
    .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
      background-color: rgba(255, 255, 255, 0.5);
    }

    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: rgba(255, 255, 255, 0.5);
    }

  }

  .body {
    width: 1400px;
    height: 820px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);
    position: absolute;
    left: 50%;
    transform: translate(-50%, -17%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #505050;

    img {
      width: 112px;
      height: 112px;
    }
  }
}