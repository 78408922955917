.mypingjia {
  width: 1220px;
  height: 834px;
  border: 1px solid #E9E9E9;
  padding-right: 22px;
  padding-left: 30px;

  .pingjiatop {
    border-bottom: 1px dashed #E9E9E9;
    ;
    padding-bottom: 20px;

    .title {
      padding-top: 23px;
      padding-left: 30px;
      margin-bottom: 30px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4A4A4A;
      line-height: 22px;
      letter-spacing: 1px;
    }

    .shaixuan {
      display: flex;
      padding-left: 40px;
      justify-content: space-between;

      .shaixuanleft {
        display: flex;

        .content {
          margin-right: 30px;
        }

        .type {
          margin-right: 40px;
        }

        .time {
          .heng {
            margin-left: 10px;
            margin-right: 10px;
          }

          input {
            width: 108px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
          }
        }
      }


      .shaixuanright {
        display: flex;

        .chaxun {
          cursor: pointer;
          margin-right: 10px;
          text-align: center;
          line-height: 32px;
          width: 65px;
          height: 32px;
          background: #D3040F;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }

        .chongzhi {
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          width: 65px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #D9D9D9;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }

  .biaodan {
    // width: 1400px;
    margin: auto;
    margin-top: 23px;
    margin-bottom: 50px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .listTable {
      width: 100%;

      .listThead {
        // width: 1400px;
        height: 56px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 56px;
          line-height: 56px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          th {
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }

      .listTbody {
        // width: 1400px;
        height: 69px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          height: 69px;
          // line-height: 69px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          td {
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }

          .xiangqing {
            color: #D3040F;
            cursor: pointer;
          }
        }
      }
    }

  }
  .ant-pagination{
    text-align: right;
    margin-right: 68px;
  }
}