.myindex {
  .top {
    width: 1220px;
    height: 100px;
    background: #F7F7F7;
    border: 1px solid #EEEEEE;
    display: flex;
    // align-items: center;
    padding: 25px 20px 0 20px;
    justify-content: space-between;

    .img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .a1 {
      width: 200px;
      display: flex;

      .na {
        display: inline-block;
        width: 60px;
        height: 25px;
        background: rgba(185, 33, 21, 0.05);
        border: 1px solid #B92115;
        border-radius: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #B92115;
        text-align: center;
        line-height: 25px;
        margin-left: 6px;
      }

      .openVip {
        margin-top: 4px;
        text-align: center;
        line-height: 25px;
        width: 72px;
        height: 25px;
        background: rgba(174, 100, 11, 0.05);
        border: 1px solid #AE640B;
        border-radius: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #AE640B;
      }
    }

    .a2 {
      margin: 0 36px;

      >div:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
      }

      >div:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #B92115;
      }

      .numBalance {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #505050;
      }

      .balance {
        margin-bottom: 15px;

        span:nth-child(1) {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
        }

        span:nth-child(2) {
          cursor: pointer;
          font-size: 12px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #B92115;
        }
      }

      .topUpWithdraw {
        display: flex;
        text-align: center;

        .topUpBtn {
          margin-right: 19px;
          line-height: 22px;
          width: 60px;
          height: 26px;
          background: #B92115;
          border: 1px solid #EEEEEE;
          border-radius: 13px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }

        .WithdrawBtn {
          line-height: 22px;
          width: 60px;
          height: 26px;
          background: #3699FF;
          border: 1px solid #EEEEEE;
          border-radius: 13px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }

  .top2 {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .b1 {
      width: 847px;
      height: 170px;
      background: #F7F7F7;
      border: 1px solid #EEEEEE;
      padding: 20px;

      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #010101;
      }

      .list {
        display: flex;
        justify-content: space-between;
        height: 100px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

        >div {
          display: flex;
          align-items: center;
          position: relative;

          &::after {
            content: '';
            width: 1px;
            height: 34px;
            background: #E5E5E5;
            position: absolute;
            left: -30px;
            top: 32px;
          }

          img {
            width: 24px;
            height: 24px;
          }

          &>a {
            display: flex;
            align-items: center;
          }

          &>a>span:nth-child(2) {
            margin: 0 10px 0 4px;
          }

          &>a>span:nth-child(3) {
            font-weight: bold;
            color: #B92115;
          }
        }

        >div:nth-child(1) {
          &::after {
            content: '';
            width: 0px;
            height: 0px;
          }
        }
      }
    }

    .b2 {
      width: 350px;
      height: 170px;
      background: #F7F7F7;
      border: 1px solid #EEEEEE;
      padding: 20px;

      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #010101;
      }

      .nei {
        display: flex;
        width: 253px;
        margin-top: 36px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

        div:nth-child(1) {
          margin-right: 70px;
        }

        div>span {
          margin-left: 14px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #010101;
        }
      }
    }
  }

  .tuijian {
    width: 100%;

    .title {
      width: 1220px;
      height: 50px;
      background: #F7F7F7;
      padding: 16px 13px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
    }

    .item1 {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .item111 {
        width: 305px;
        margin-right: 0px;
        margin-bottom: 0px;
        box-shadow: none;
        border: 1px solid #EEEEEE;
      }
    }
  }
}