
:global(#components-form-demo-normal-login){
  .login-form {
    max-width: 300px;
  }
}
:global(.ant-form-large .ant-form-item-control-input){
  min-height: 50px;
}
:global(.ant-input-affix-wrapper-lg){
  height: 50px;
}

:global(.login-form-forgot) {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #909090 !important;
}
:global(.login-form-button) {
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}