.geren,
.qiye {
  text-align: left;

  .ant-form-item-control-input-content {
    height: 40px;
  }

  .ant-form-item-control-input {
    min-height: 40px;
  }

  .bo {
    width: 400px
  }

  .tu {
    height: 226px;

    .ant-upload.ant-upload-select-picture-card {
      width: 300px;
      height: 200px;
    }
  }

  .an {
    margin-top: 60px;
  }
}

.qiye .tu {
  text-align: left;
}