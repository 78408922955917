.orderdetails{
  .width1400{
    .buzhou{
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .xinxi{
      width: 1400px;
      height: 200px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      display: flex;
      .a1{
        width: 700px;
        height: 197px;
        background: #FFFFFF;
        border-right: 1px solid #EEEEEE;
        .title{
          width: 700px;
          height: 45px;
          background: #F9F9F9;
          border-bottom: 1px solid #EEEEEE;
          border-right: 1px solid #eee;
          line-height: 45px;
          padding-left: 20px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .aa{
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
          padding: 10px 20px;
          line-height: 24px;
        }
        .bb{
          display: flex;
          >div{
            width: 350px;
          }
        }
      }
      .a2{
        width: 100%;
        padding: 20px 20px 0 40px;
        >div:nth-child(1){
          display: flex;
          align-items: center;
          >span:nth-child(2){
            margin-left: 10px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
        }
        .wuliuxinxi{
          margin-top: 4px;
          >div:nth-child(1){
            display: flex;
            justify-content: space-between;
            >span:nth-child(1){
              margin-top: 7px;
            }
            .xinxi{
              width: 600px;
              height: 90px;
              background: #F9F9F9;
              display: flex;
              align-items: center;
              padding: 10px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #505050;
            }
          }
          .bo{
            margin: 10px 40px;
          }
        }
        .ding{
          padding-left: 40px;
          >div:nth-child(1){
            margin-top: 30px;
            margin-bottom: 54px;
          }
          button + button{
            margin-left: 10px;
          }
        }
      }
    }
    .list{
      width: 1400px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-bottom: 20px;
      margin-top: 20px;
      .tou{
        height: 50px;
        background: #F9F9F9;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        align-items: center;
        >span{
          margin-left: 10px;
        }
      }
      .biao{
        padding: 20px 20px 25px;
        .tu{
          width: 60px;
          height: 60px;
        }
        .bodi{
          display: flex;
          justify-content: space-between;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #EEEEEE;
          border-top: none;
          padding: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          .left{
            display: flex;
            width: 500px;
            >div{
              width: 110px;
            }
          }
          .right{
            width: 500px;
            display: flex;
            .on1{
              width: 200px;
              text-align: right;
            }
            .on2{
              width: 300px;
              text-align: right;
              color: #C33529;
            }
          }
        }
        .di{
          height: 60px;
          background: rgba(222, 50, 50, 0.05);
          border: 1px solid #EEEEEE;
          border-top: none;
          line-height: 60px;
          text-align: right;
          padding: 0 20px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          >span:nth-child(2){
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #C33529;
          }
        }
      }
      
    }
    .jie{
      width: 100%;
      height: 150px;
      margin-bottom: 20px;
      background: rgba(242, 73, 33, 0.05);
      border: 1px solid #F24921;
      text-align: right;
      padding: 20px 40px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      >div:nth-child(3){
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #010101;
        >span:nth-child(2){
         font-size: 32px;
         color: #DF3331; 
        }
      }
      >div:nth-child(1){
        >span{
          margin-left: 6px;
        }
      }
    }
  }
}