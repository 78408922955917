
#mobilelogin{
  .verification{
    margin-bottom: 0;
  }
  .yan{
    width: 330px;
    float: left;
  }
  .botton{
    float: left;
    margin-left: 16px;
    width: 112px;
    height: 50px;
  }
  .picver{
    margin-bottom: 40px;
    .yan{
      width: 270px;
      float: left;
    }
    .img{
      width: 90px;
      height: 35px;
      float: left;
      margin-top: 7px;
      margin-left: 8px;
    }
    .text{
      float: left;
      margin-left: 6px;
    }
  }

}
input[type=number] {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}