.identity {
  width: 100%;
  border-top: 1px solid #EEEEEE;

  .nei {
    width: 1400px;
    height: 500px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    margin: 20px auto 43px;
    padding: 30px;

    .on1 {
      display: flex;
      align-items: center;
      margin-top: 50px;

      >div:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 20px;
      }

      >div:nth-child(n+2) {
        width: 160px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #C7C7C7;

        >img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      >div.sty {
        background: rgba(195, 53, 41, 0.05);
        border: 1px solid #C33529;
        color: #BD2C21;
      }
    }

    .on2 {
      display: flex;
      align-items: center;
      margin-top: 50px;

      >div:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 20px;
      }

      >div:nth-child(n+2) {
        width: 90px;
        height: 35px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 30px;
        line-height: 35px;
        text-align: center;
      }

      >div.sty {
        background: rgba(195, 53, 41, 0.05);
        border: 1px solid #C33529;
        color: #BD2C21;
      }
    }

    .ant-btn {
      margin-top: 170px;
      margin-left: 120px;
      width: 270px;
      height: 45px;
    }
  }
}