.shopshoucang {
  .shopList {
    .shopItem {
      display: flex;
      padding: 20px;
      width: 1400px;
      height: 200px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;

      .shopItemLeft {
        display: flex;

        .shopLogo {
          margin-right: 10px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }

        .shopInfoRight {
          padding-top: 10px;
          // display: flex;
          width: 220px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          .shopInfoName {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #505050;
            line-height: 20px;
          }
        }
      }

      .rule {
        margin-top: 30px;
        width: 1px;
        height: 100px;
        background: #E5E5E5;
      }
    }
  }

  .list {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    padding: 20px 0;
  }

  .shopItemRight {
    display: flex;

    .shopItemGoods {
      width: 140px;
      height: 165px;
      background: #FFFFFF;
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 0 !important;
      margin-left: 20px;

      &:nth-child(5n) {
        margin-right: 0px;
      }

      .img {
        width: 140px;
        height: 140px;
      }

      .you {
        .youpin {
          width: 70px;
          height: 25px;
          background: rgba(189, 44, 33, 0.05);
          border: 1px dashed #BD2C21;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #BD2C21;
          text-align: center;
          line-height: 23px;
          border-radius: 2px;
          margin-right: 10px;
        }

        .name {
          width: 116px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          text-align: center;

        }
      }
    }
  }

}