.cuoheW {
  width: 100%;
  min-height: 200px;

  .cuohe {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .ant-tabs {
    width: 100%;
    display: flex;
    background: #F7F7F7;

    .ant-tabs-nav {
      width: 600px;
      height: 70px;
      margin: auto;
    }

    .ant-tabs-nav-wrap {
      justify-content: center
    }

    .ant-tabs-nav-list {
      width: 600px;
      justify-content: space-between;
    }
  }

  .ant-tabs-content-holder {
    background: #FFF;
  }
}