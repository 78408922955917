.pricedistributionarea {
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0;
  width: 1400px;
  height: 500px;
  background: #FFFFFF;

  .pricedistributionareaLeft,
  .pricedistributionareaRight {
    padding: 0 20px 0 30px;
    width: 690px;
    height: 497px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .pricedistributionareaTop {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #EEEEEE;

      .left {
        display: flex;
        line-height: 30px;

        .red {
          width: 8px;
          height: 30px;
          background: #E64F38;
          margin-right: 15px;
        }

        .title {
          font-size: 28px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: #000000;
        }
      }
      .right {
        display: flex;
        text-align: center;
        line-height: 40px;
  
        .item {
          margin-right: 30px;
          width: 100px;
          height: 40px;
          background: rgba(230, 79, 56, 0.05);
          border: 1px solid #E64F38;
          font-size: 18px;
          font-family: PingFang;
          font-weight: 500;
          color: #E64F38;
        }
  
        .active {
          width: 100px;
          height: 40px;
          background: #E64F38;
          font-size: 18px;
          font-family: PingFang;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }

    .listTable {
      width: 100%;

      .listThead {
        width: 630px;
        height: 40px;
        background: #E64F38;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 40px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          th {
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      .listTbody {
        width: 1400px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          text-align: center;
          height: 40px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;

          &:nth-child(odd) {
            background: #FAFAFA;
            background: #FAFAFA;
            border: 1px solid #EEEEEE;
          }
          &:nth-child(even) {
            background: rgba(230, 79, 56, 0.5);
            border: 1px solid #EEEEEE;
          }
        }
      }
    }
  }

  .view {
    display: flex;
    justify-content: space-between;

    .map {
      width: 800px;
      height: 470px;
    }

    .crosswise {
      width: 700px;
      height: 580px;
      background: #F7F7F7;
    }
  }
}