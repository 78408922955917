.gongying {
  .ant-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .ant-form-item-control {
      display: block;
      flex-grow: 0;

      .ant-form-item-control-input {
        width: 600px;

        .ant-input {
          width: 600px;
          height: 40px;
          background: #FAFAFA;
          border: 1px solid #EEEEEE;
        }

        .ant-select-selector {
          width: 600px;
          height: 40px;
          background: #FAFAFA;
          border: 1px solid #EEEEEE;

          .ant-select-selection-item {
            line-height: 40px;
          }
        }
      }
    }
  }

  .explain {
    display: flex;
    justify-content: flex-end;

    .spantext {
      display: block;
      width: 80px;
      height: 80px;
      text-align: right;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
    }

    textarea {
      width: 600px;
      height: 80px;
      padding-top: 13px;
      padding-left: 10px;
      background: #FAFAFA;
      border: 1px solid #EEEEEE;
      resize: none;
    }
  }

  .qiwanghuoyuan {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .qiwangspan{
      height: 32px;
    line-height: 32px;
      display: block;
      width: 84px;
      text-align: right;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
    }
    .ant-cascader-input {
      width: 600px;
      height: 40px;
      background-color: #FAFAFA !important;
      border: 1px solid #EEEEEE;
    }
  }
}

.ant-cascader-menu {
  .ant-cascader-menu {
    .ant-cascader-menu-item {
      float: none;
    }

  }
}