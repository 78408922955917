.xgmm{
  .yanzhengma {
    margin-bottom: 20px;
    overflow: hidden;
    input {
      padding-left: 10px;
      float: left;
      width: 224px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #DADBDD;
    }
  
    button {
      cursor: pointer;
      border: none;
      float: right;
      width: 110px;
      height: 32px;
      background: #F6F6F6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
  
  .shuruNewPassword {
    margin-bottom: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  
    input {
      padding-left: 10px;
      width: 258px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #DADBDD;
    }
  }
  
  .querenNewPassword {
    margin-bottom: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  
    input {
      padding-left: 10px;
      width: 258px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #DADBDD;
    }
  }
  
  .queren {
    width: 124px;
    height: 44px;
    background: #D3040F;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
    letter-spacing: 1px;
  }

}