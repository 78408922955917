.search {
  .searchwai {
    width: 100%;
    height: 70px;
    background: #F7F7F7;
    border: 1px solid #EEEEEE;

    .searchnei {
      width: 1400px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;

      .dataList{
        display: flex;
        width: 1360px;
        height: 70px;
        align-items: center;
        white-space: nowrap;overflow-x: auto;-webkit-overflow-scrolling:touch;
      }

      .item {
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        margin-left: 20px;
        border: 1px solid #e3e3e3;
        padding:10px 20px;
        display: flex;
        align-items: center;
      }

      .sta {
        background: rgba(189, 44, 33, 0.05);
        border: 1px solid #BD2C21;
        color: #BD2C21;
      }
    }
  }
  .list{
    display: flex;
    flex-wrap: wrap;
  
    .item111 {
      width: 270px;
      height: 450px;
      background: #FFFFFF;
      box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.3);
      padding: 20px 15px;
      cursor: pointer;
      margin: 0 10px 10px 0;
    
      &:nth-child(5n) {
        margin-right: 0px;
      }
    
      .img {
        width: 100%;
        height: 240px;
      }
    
      .jia {
        height: 62px;
        line-height: 62px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #BD2C21;
    
        span {
          font-size: 24px;
        }
      }
    
      .you {
        display: flex;
        height: 25px;
        align-items: center;
    
        .youpin {
          width: 70px;
          height: 25px;
          background: rgba(189, 44, 33, 0.05);
          border: 1px dashed #BD2C21;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #BD2C21;
          text-align: center;
          line-height: 23px;
          border-radius: 2px;
          margin-right: 10px;
        }
    
        .name {
          width: 116px;
          height: 18px;
          font-size: 18px;
          line-height: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
    
        }
      }
    
      .dian {
        display: flex;
        margin-top: 20px;
        height: 52px;
    
        .dianlogo {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
        }
    
        .dianright {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
          width: 160px;
    
          &:nth-child(2) {
            color: #909090;
          }
        }
      }
    
      .diandi {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #909090;
    
        .icon {
          margin-right: 4px;
    
          svg {
            width: 16px;
            height: 16px;
            font-size: 16px;
            color: #909090;
          }
        }
      }
    }
  }
}