.caigoudating {
  .xuanzelanW {
    width: 100%;
    height: 60px;
    background: #FAFAFA;

    .xuanzelan {
      margin: auto;
      width: 1400px;
      height: 60px;
      background: #FAFAFA;

      .xuanzeleft {
        float: left;
        width: 1158px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 10px;
      }

      .ant-space {
        .ant-space-item {
          .ant-btn {
            padding: 0;
            width: 120px;
            height: 35px;
            background: #FFFFFF;

            .anticon {
              float: right;
              padding-top: 5px;
            }
          }
        }
      }

      .caigounum {
        input {
          width: 120px;
          height: 35px;
          background: #FFFFFF;
          border: 1px solid #E5E5E5;
        }
      }

      .jiage input {
        width: 100px;
        height: 35px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
      }

      .fabucaigou {
        float: right;

        .ant-btn {
          width: 100px;
          height: 40px;
          background: #BD2C21;

          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .biaodan {
    width: 1400px;
    margin:23px auto 50px;
    height: 650px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .listTable {
      width: 100%;

      .listThead {
        width: 1400px;
        height: 60px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          th {
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }

      .listTbody {
        width: 1400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          .ckxq {
            color: #BD2C21;
            cursor: pointer;
          }
        }
      }
    }

  }

  .fenyeqi .ant-pagination {
    display: flex;
    justify-content: center;
  }
}

.caigouModal {
  .ant-modal-content {
    width: 800px;
    background: #FFFFFF;
    .ant-modal-body{
      padding-top: 10px;
      padding-right: 75px;
    }
    .ant-modal-footer{
      border-top: none;
      text-align: center;
    }
  }
  
}