.homebottom {
  width: 100%;
  background: #F9F9F9;

  .one {
    border-bottom: 1px solid #EEEEEE;
    height: 50px;
    text-align: center;
    line-height: 50px;

    span {
      margin-right: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #505050;
    }

    .a {
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .two {
    width: 100%;
    height: 128px;
    border-bottom: 1px solid #EEEEEE;

    .twonei {
      width: 1400px;
      margin: auto;
      display: flex;
      align-items: center;
      height: 100%;

      .nei1 {
        flex: 1;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        .homebottomimg1 {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }

        .homebottomright {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;
          text-align: left;

          >div:nth-child(1) {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #C33529;
            margin-top: 9px;
          }

          >div:nth-child(n+2):hover {
            opacity: 0.6;
            cursor: pointer;
          }
        }
      }

      .nei2 {
        flex: 1;
        text-align: right;

        >div {
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #909090;

          img {
            width: 90px;
            height: 90px;
          }
        }

      }
    }
  }
}