.financialDetails {
  .filtrate {
    padding: 19px 0 36px 21px;
    display: flex;

    .time {
      margin-right: 30px;

      .lookTime {
        margin-right: 10px;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
      }
    }

    .serialNumber {
      .serialNumberSpan {
        margin-right: 10px;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
      }

      .serialNumberInput {
        margin-right: 50px;
        width: 160px;
        height: 35px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
      }

      .ant-btn {
        padding: 0;
        width: 60px;
        height: 25px;
        background: #B92115;
        border: 1px solid #EEEEEE;
        border-radius: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .financialDetailsTable {
    .listTable {
      width: 100%;

      .listThead {
        width: 1400px;
        height: 60px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #505050;

          th {
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }

      .listTbody {
        text-align: center;
        width: 1400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);
          color: #BD2C21;
        }

        .TbodyTr {
          table-layout: fixed;
          height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;

        }
      }
    }
    .ant-pagination{
      margin-top: 50px;
      text-align: center;
    }
  }
}