.toevalueW {
  .toevaluate {
    display: flex;
    width: 1400px;
    margin: 20px auto 0;
    padding: 30px 50px;
    height: 375px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;

    .toevaluateleft {
      margin-right: 60px;

      .pic {
        margin-bottom: 9px;
        width: 120px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        .name {
          font-size: 14px;
          font-family: PingFang;
          font-weight: 500;
          color: #000000;
        }

        .lv {
          font-size: 12px;
          font-family: PingFang;
          font-weight: 500;
          color: #909090;
          line-height: 20px;
        }
      }
    }

    .toevaluateright {
      .evaluatergTop {
        margin-bottom: 20px;
        font-size: 14px;
        font-family: PingFang;
        font-weight: 500;
        color: #909090;
        line-height: 20px;

        .minute {
          margin-left: 12px;
          font-size: 16px;
          font-family: PingFang;
          font-weight: 500;
          color: #BD2C21;
          line-height: 20px;
        }
      }

      .evaluatergContent {
        margin-top: 10px;

        .evaluatergText {
          padding: 20px;
          width: 1000px;
          height: 100px;
          background: #F9F9F9;
          border: 1px solid #EEEEEE;
          font-size: 14px;
          font-family: PingFang;
          font-weight: 500;
          resize: none;
        }
      }
    }
  }

  .btn {

    width: 1400px;
    margin: 35px auto 47px;
    text-align: center;

    .ant-btn {
      width: 140px;
      height: 45px;
      background: #BD2C21;
      font-size: 16px;
      font-family: PingFang;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
    }
  }
}