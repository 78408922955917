.auditresult {
  .auditresultTopW {
    height: 300px;
    background: #B92115;

    .auditresultTop {
      overflow: hidden;
      margin: auto;
      width: 1400px;
      height: 300px;
      background: #B92115;

      .step {
        margin: 54px auto 0;
        width: 800px;

        .ant-steps {
          .ant-steps-item {
            .ant-steps-item-content {
              .ant-steps-item-title {
                color: #FFFFFF;


              }

              .ant-steps-item-title::after {
                background-color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }

  .auditresultContent {
    overflow: hidden;
    margin: -150px auto 70px;
    width: 1400px;
    height: 820px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .status {
      text-align: center;
      margin-top: 250px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #505050;

      .up {
        cursor: pointer;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FF4A2A;
      }
    }
  }
}