.ant-input-group-wrapper{
  width: 662px;
}
.search{
  width: 100%;
  .topwai{
    width: 100%;
    height: 60px;
    background: #FAFAFA;
    .topnei{
      width: 1402px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .o1{

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        &:hover{
          opacity: 0.6;
        }
      }
      .o2,.o3{
        .span{
          margin: 0 10px;
        }
      }
      .o3{
      }
      .o4{
        width: 180px;
        text-align: right;
        .span1{
          margin: 0 10px;
        }
      }
    }
  }
  .list{
    width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    padding: 20px 0;
  }
  .seardi{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}