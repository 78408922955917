.order1 {
  .width1400 {
    .biaotou {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EEEEEE;

      .list {
        height: 100%;
        display: flex;
        align-items: center;

        .item {
          width: 100px;
          height: 100%;
          line-height: 48px;
          text-align: center;
          font-size: 16px;
          font-family: PingFang;
          font-weight: 500;
          color: #000000;
        }

        .st {
          color: #BD2C21;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 2px;
            background: #BD2C21;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .biaotou1 {
      width: 1400px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-top: 20px;
      display: flex;
      margin-bottom: 20px;

      >div {
        flex: 1;
        line-height: 40px;
        text-align: center;
      }

      >div:nth-child(1) {
        flex: 3;
      }
    }

    .ul {
      .li {
        width: 1400px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        margin-bottom: 10px;

        .a {
          width: 1400px;
          height: 40px;
          background: #F9F9F9;
          display: flex;
          align-items: center;
          padding: 0 28px;
          font-size: 14px;
          font-family: PingFang;
          font-weight: 500;
          color: #000000;

          >span {
            margin-right: 50px;
          }

          >span:nth-child(1) {
            margin-right: 10px;
          }

          >span:nth-child(3) {
            margin-right: auto;
          }

          >span:nth-child(4) {
            margin-right: 0;
          }
        }

        .b {
          .flex1 {
            display: flex;
            height: 110px;
            border-top: 1px solid #EEEEEE;

            >div {
              flex: 1;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            >div:nth-child(1) {
              flex: 3;

              img {
                width: 70px;
                height: 70px;
                margin-right: 14px;
              }

              >div:nth-child(2) {
                text-align: left;
                font-size: 12px;
                font-family: PingFang;
                font-weight: 500;
                color: #505050;
                line-height: 20px;

                >div:nth-child(1) {
                  font-size: 14px;
                  font-family: PingFang;
                  font-weight: 500;
                  color: #000000;
                }
              }
            }

            >div:nth-child(4) {
              font-size: 14px;
              font-family: PingFang;
              font-weight: 500;
              color: #505050;
              line-height: 20px;
              display: flex;
              flex-flow: column;

              .fontw {
                font-size: 14px;
                font-family: PingFang;
                font-weight: 500;
                color: #000000;
              }
            }

            >div:nth-child(5) {
              font-size: 14px;
              font-family: PingFang;
              font-weight: 500;
              color: #000000;
            }

            >div:nth-child(6) {
              display: flex;
              flex-flow: column;

              button+button {
                margin-top: 6px;
              }
            }
          }
        }
      }
    }

    .rightbuttom {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 140px;
    }
  }
}

.aftersaleModal {
  .ant-modal-content {
    width: 750px;
    height: 600px;
    background: #FFFFFF;

    .ant-modal-body {
      .shouhou {
        .ant-radio-group {
          margin-bottom: 24px;
        }

        .text {
          display: flex;

          span {
            display: inline-block;
            width: 79px;
          }
        }

        .upLoadPic {
          justify-content: flex-start;
        }

        .uploadTxt {
          width: 70px;
        }
      }
    }

    .ant-modal-footer {
      text-align: center;

      .ant-btn {
        width: 140px;
        height: 45px;
        background: #BD2C21;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
      }


    }
  }
}