.ranking {
  
 .varietyList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
   .varietyItem{
     cursor: pointer;
     width: 50px;
     height: 30px;
     line-height: 30px;
     text-align: center;
   }
   .active{
    background: rgba(189, 44, 33, 0.05);
    border: 1px solid #BD2C21;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #BD2C21;
   }
 }

  .ant-table-tbody {
    background-color: rgba(215, 56, 12, 0.05);
  }

  .ant-table-thead>tr>th {
    background-color: rgba(215, 56, 12, 0.05);
  }

  .ant-table-tbody>tr {
    >td {
      border-bottom: none;
    }
  }

  .ant-table-tbody {

    >tr:hover:not(.ant-table-expanded-row)>td,
    .ant-table-row-hover,
    .ant-table-row-hover>td {
      background: rgba(215, 56, 12, 0.25) !important;
      cursor: pointer;
    }
  }

  .ant-table-fixed {

    .ant-table-row-hover,
    .ant-table-row-hover>td {
      background: rgba(215, 56, 12, 0.25) !important;
      cursor: pointer;
    }
  }
}