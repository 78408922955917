.mychuzu {
  width: 100%;
  height: 727px;
  background: #FFFFFF;
  border: 1px solid #E9E9E9;

  .ant-pagination {
    margin-top: 10px;
  }

  .biaoti {
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4A4A4A;
    line-height: 22px;
    letter-spacing: 1px;
  }

  .chuzu {
    padding-left: 30px;
    padding-right: 26px;

    .chuzutab {
      display: flex;
      text-align: center;
      line-height: 42px;
      justify-content: center;

      .tabitem {
        width: 388px;
        height: 42px;
        background: #EFEFEF;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
      }

      .active {
        background: #D3040F;
        color: #FFFFFF;
      }

    }

    .chuzulist {
      height: 514px;

      .chuzuitem {
        padding: 20px 44px 10px 40px;
        width: 1160px;
        height: 104px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        justify-content: space-between;

        .itemleft {
          .item-name {
            margin-bottom: 10px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4A4A4A;
            line-height: 20px;
            letter-spacing: 1px;
          }

          .zhong {
            margin-bottom: 5px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D3040F;
            line-height: 20px;
            letter-spacing: 1px;
          }

          .pinzhi {
            width: 318px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9A9A9A;
            line-height: 17px;
          }
        }

        .itemright {
          .baojia {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D3040F;
            line-height: 20px;
            letter-spacing: 1px;
            margin-bottom: 15px;

            .you {
              background-image: url('../../../static/img/you.png');
              background-size: 4px 8px;
              background-repeat: no-repeat;
              width: 8px;
              height: 8px;
              display: inline-block;
            }
          }

          .guanli {
            // width: 60px;
            // height: 32px;
            // text-align: center;
            // line-height: 32px;
            // background: #FFFFFF;
            // border: 1px solid #D9D9D9;
            // font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            // color: #333333;

            .ant-btn {
              width: 60px;
              height: 32px;
              background: #FFFFFF;
              border: 1px solid #D9D9D9;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 22px;
            }
          }
        }

      }
    }
  }

  .ant-pagination {
    text-align: right;
    padding-right: 70px;
  }
}

.gunaliModal {
  .ant-modal-content {
    width: 534px;
    height: 100%;

    background: #FFFFFF;
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      background: #F6F6F6;
      border-radius: 10px 12px 0px 0px;

      .ant-modal-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }
    }

    .ant-modal-body {
      padding: 10px 20px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: none;

      .ant-btn:nth-child(1) {
        width: 124px;
        height: 44px;
        background: #D3040F;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
      }

      .ant-btn:nth-child(2) {
        width: 124px;
        height: 44px;
        background: #F47702;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
}

.guanliinfo {

  .guanlixiangqing {
    padding: 10px 34px;
    width: 494px;
    height: 85px;
    background: #F7F8FB;
    border-radius: 4px;
    margin-bottom: 20px;

    .guanliname {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .guanlizhong {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #D3040F;
      line-height: 20px;
    }

    .guanlipinzhi {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9A9A9A;
      line-height: 17px;
    }
  }

  .chuzutype {
    .chuzutypeitem {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      div:nth-child(1) {
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }

      div:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }

      div {
        width: 50%;
      }
    }
  }
}

.chuzuModal {
  .ant-modal-content {
    width: 800px;

    .ant-modal-body {
      #nest-messages {
        .ant-row {
          .ant-col {
            width: 70px;
          }
        }
      }
      .qiwanghuoyuan{
        .ant-cascader-picker{
          width: 682px;
        }
      }
      .upLoadPic{
        display: flex;
        justify-content: flex-start;
        .uploadTxt{
          width: 70px;
        }
      }
      .explain{
        display: flex;
       
        .spantext{
          display: inline-block;
          width: 70px;
          
        }
        textarea{
          width: 680px;
          height: 62px;
        }
      }
    }
  }

}