.futurestrading {
  .futurestradingBlankTop {
    height: 70px;
    background: #F7F7F7;
    border: 1px solid #EEEEEE;
  }

  .futurestradingConten {
    .futurestradingContenTopW {
      height: 60px;
      background: #FAFAFA;

      .futurestradingContenTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        width: 1400px;
        height: 60px;
        background: #FAFAFA;

        .contentTopLeft {
          display: flex;
          align-items: center;

          .contract {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #000000;
            background: #FAFAFA;

            span {
              margin-right: 9px;
            }

            Input {
              width: 120px;
              height: 35px;
              background: #FFFFFF;
              border: 1px solid #E5E5E5;
            }
          }

          .class,
          .breed {
            .span {
              margin-right: 9px;
            }
          }

          .search {
            margin-right: 20px;
            cursor: pointer;
            width: 55px;
            height: 35px;
            background: #B92115;
            background-image: url('../../../static/img/search.png');
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .contentTopRight {
          display: flex;

          .balance {
            margin-right: 26px;
            line-height: 40px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #BD2C21;
          }

          .balanceBtn {
            margin-left: 12px;
            cursor: pointer;
            width: 100px;
            height: 40px;
            background: #BD2C21;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
          }
        }
      }
    }

    .ant-pagination {
      text-align: center;
    }
  }

  .futurestradingContenTable {
    width: 1400px;
    margin: 23px auto 50px;
    height: 650px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .listTable {
      width: 100%;

      .listThead {
        // width: 1400px;
        height: 60px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;

        .listTr {
          table-layout: fixed;
          height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          th {
            // width: 48px;
            text-align: center;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #505050;
       
          }
          .sort {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .rightSort {
              display: flex;
              flex-direction: column;
            }
          }

          .tabOperationTh {
            text-align: end;
            padding-right: 20px;
          }
        }
      }

      .listTbody {
        // width: 1400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #F7F7F7;

        .TbodyTr:hover {
          background: rgba(189, 44, 33, 0.05);

          td {
            color: #BD2C21;
          }

        }

        .TbodyTr {
          table-layout: fixed;
          height: 60px;
          border-top: 1px solid #EEEEEE;
          border-left: 1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          td {
            text-align: center;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #000000;
          }

          .tabOperationTd {
            height: 60px;
            padding-right: 12px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            span {
              cursor: pointer;
              font-size: 16px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #BD2C21;
            }
          }
        }
      }
    }
  }
}

//卖出里面的持仓里面的卖出的窗口
.balanceModal {
  .ant-modal-content {

    .ant-modal-body {
      height: 320px;

      .balanceModalContent {
        display: flex;
        flex-direction: column;

        .balanceModalContentTop {
          display: flex;
          justify-content: space-around;
          height: 72px;
          align-items: center;
        }

        .balanceModalContentBottom {
          cursor: pointer;
          margin: 50px auto 0;
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          background: #FAFAFA;
          border: 1px solid #EEEEEE;

          div {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #17BD4E;
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 0;
    }
  }
}

// 点击聊一聊弹窗
.chatModal {
  .ant-modal-content {
    width: 589px;

    .ant-modal-body {
      height: 184px;

      .chatModalInfo {
        margin-top: 30px;
        line-height: 40px;
        width: 549px;
        height: 40px;
        background: #FAFAFA;
        border: 1px solid #EEEEEE;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #505050;
        line-height: 20px;
      }
    }

    .ant-modal-footer {
      height: 100px;
      background: #FAFAFA;
      border: 1px solid #EEEEEE;

      .liao {
        display: flex;
        align-content: space-between;
      }
    }
  }
}

// 点击卖出的弹窗
.balanceTabModal {
  width: 1000px !important;

  .ant-modal-content {
    width: 1000px;

    .ant-modal-body {
      // height: 382px;

      .balanceTab {
        margin-top: 20px;

        .transactionList {
          display: flex;

          .transactionItem {
            cursor: pointer;
            margin-right: 20px;
            line-height: 30px;
            text-align: center;
            width: 80px;
            height: 30px;
            background: #FAFAFA;
            border: 1px solid #BD2C21;
            border-radius: 15px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #BD2C21;
          }

          #active {
            background: #BD2C21;
            color: #FFFFFF;
          }
        }

        .positionTable {
          margin-top: 30px;

          .listTable {
            width: 100%;

            .listThead {
              height: 40px;
              background: #FAFAFA;
              border: 1px solid #EEEEEE;

              .listTr {
                table-layout: fixed;
                height: 40px;
                border-top: 1px solid #EEEEEE;
                border-left: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #505050;

                th {
                  text-align: center;
                  font-size: 16px;
                  font-family: Adobe Heiti Std;
                  font-weight: normal;
                  color: #505050;
                }

                .tabOperationTh {
                  text-align: end;
                  padding-right: 20px;
                }
              }
            }

            .listTbody {
              // width: 1400px;
              height: 40px;
              background: #FAFAFA;
              border: 1px solid #EEEEEE;

              .TbodyTr:hover {
                background: rgba(189, 44, 33, 0.05);

                td {
                  color: #BD2C21;
                }

              }

              .TbodyTr {
                table-layout: fixed;
                height: 40px;
                border-top: 1px solid #EEEEEE;
                border-left: 1px solid #EEEEEE;
                border-right: 1px solid #EEEEEE;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;

                td {
                  text-align: center;
                  font-size: 16px;
                  font-family: Adobe Heiti Std;
                  font-weight: normal;
                  color: #000000;
                }

                .tabOperationTd {
                  height: 40px;
                  padding-right: 20px;
                  text-align: right;

                  span {
                    cursor: pointer;
                    font-size: 16px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #BD2C21;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 0;
    }
  }
}

.tiHuoModal {

  .ant-modal-content {
    .ant-modal-header {
      text-align: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #1F1E1E;
    }

    .ant-modal-body {
      padding: 0;

      .tiHuoContent {
        height: 620px;
        padding: 24px 42px;
        background: #FFF;

        .tiHuoContent_item {
          margin-bottom: 26px;
          display: flex;
          justify-content: space-between;

          .tiHuoContent_itemName {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }

          .tiHuoContent_Value {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #5D5D5D;

            .cachet {
              position: absolute;
              right: 0px;
              bottom: 108px;
              width: 173px;
              height: 121px;
              background: url(../../../static/img/cachet.png) no-repeat;
            }
          }
        }
      }

      .tiHuoModalBtn {
        text-align: center;

        .ant-btn {
          width: 203px;
          height: 41px;
          background: #C5353D;
          border: 1px solid #BD2C21;
          border-radius: 20px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;
        }

      }
    }

    .ant-modal-footer {
      border: none;
    }
  }
}

.addOrderModal {
  width: 1000px !important;

  .ant-modal-content {
    width: 1000px;

    .ant-modal-body {
      height: 320px;

      .addOrderModalContent {
        display: flex;
        flex-direction: column;

        .listTable {
          width: 100%;

          .listThead {
            // width: 1400px;
            height: 40px;
            background: #FAFAFA;
            border: 1px solid #EEEEEE;

            .listTr {
              table-layout: fixed;
              height: 40px;
              border-top: 1px solid #EEEEEE;
              border-left: 1px solid #EEEEEE;
              border-right: 1px solid #EEEEEE;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #505050;

              th {
                // width: 48px;
                text-align: center;
                font-size: 14px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #505050;
              }
            }
          }

          .listTbody {
            // width: 1400px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #F7F7F7;

            .TbodyTr:hover {
              background: rgba(189, 44, 33, 0.05);

              td {
                color: #BD2C21;
              }

            }

            .TbodyTr {
              table-layout: fixed;
              height: 40px;
              border-top: 1px solid #EEEEEE;
              border-left: 1px solid #EEEEEE;
              border-right: 1px solid #EEEEEE;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              td {
                text-align: center;
                font-size: 14px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #000000;
              }
            }
          }
        }

        .summation {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;

          .summationLeft {

            .goodsPrice {
              font-size: 16px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #363636;

              .goodsPriceName {
                margin-right: 73px;
              }
            }

            .rent {
              font-size: 16px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #363636;

              .rentName {
                margin-right: 113px;
              }
            }
          }

          .summationRight {
            display: flex;
            line-height: 22px;
            align-items: center;
            font-size: 16px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #363636;

            .Price {
              height: 20px;
              font-size: 24px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #363636;
            }
          }
        }


        // .balanceModalContentTop {
        //   display: flex;
        //   justify-content: space-around;
        //   height: 72px;
        //   align-items: center;
        // }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 50px auto 0;
          width: 203px;
          height: 41px;
          background: #C5353D;
          border: 1px solid #BD2C21;
          border-radius: 20px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 20px;
        }

        .addOrderModalContentBottom {
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          margin: 50px auto 0;
          width: 203px;
          height: 41px;
          background: #C5353D;
          border: 1px solid #BD2C21;
          border-radius: 20px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;
        }
      }
    }

    .ant-modal-footer {
      padding: 0;
    }
  }
}