.details {
  width: 100%;

  .towai {
    width: 100%;
    height: 200px;
    background: #B92115;

    .tonei {
      width: 1400px;
      height: 100%;
      padding: 25px;
      display: flex;
      margin: auto;
      font-weight: 400;
      color: #FFFFFF;

      .to1 {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }

      .to2 {
        width: 310px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 2;

        >div:nth-child(1) {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1;
          height: 40px;
        }
      }

      .to3 {
        width: 800px;
        height: 150px;
        background: #C83F33;
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
          flex: 1;
          text-align: center;
          position: relative;

          &::after {
            content: '';
            width: 1px;
            height: 60px;
            background: #B92115;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          >div:nth-child(1) {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #FFFFFF;
          }

          >div:nth-child(2) {
            font-size: 28px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        >div:nth-child(4)::after {
          content: '';
          width: 0;
          height: 0;
        }
      }

      .to4 {
        width: 150px;
        height: 100%;
        text-align: right;

        >div {
          width: 100px;
          height: 40px;
          background: #FFFFFF;
          line-height: 40px;
          text-align: center;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #B92115;
          border: 1px solid #FFFFFF;
          margin-left: 50px;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }

        >div:nth-child(1) {
          margin-top: 35px;
        }

        >div:nth-child(2) {
          color: #FFFFFF;
          background: #B92115;
          margin-top: 10px;
        }
      }
    }
  }

  .width1400 {
    width: 1400px;
    margin: auto;
    margin-bottom: 20px;

    .ant-breadcrumb {
      height: 54px;
      line-height: 54px;
    }
  }

  .xiangqing {
    width: 1400px;
    margin: auto;
    display: flex;

    .left {
      width: 1230px;
      border-right: 1px solid #EEEEEE;

      .lefttop {
        display: flex;

        .leftleft {
          width: 350px;
          margin-right: 20px;

          .list {
            height: 58px;
            width: 100%;
            margin: 14px 0;
            display: flex;

            .imglist {
              height: 58px;
              width: 58px;
              margin-right: 10px;
            }

            .active {
              border: 1px solid #BD2C21;
            }
          }

          .an {
            height: 30px;
            line-height: 30px;

            >span {
              margin-right: 30px;
              cursor: pointer;
              color: #666666;
              display: inline-block;
              width: 65px;

              &:hover {
                opacity: 0.6;
              }

              >span:nth-child(2) {
                margin-left: 8px;
              }
            }
          }
        }

        .leftright {
          width: 880px;
          position: relative;

          .margin38 {
            margin-right: 38px;
          }

          .on1 {
            height: 65px;
            width: 100%;
            display: flex;
            align-items: center;

            .test {
              width: 70px;
              height: 25px;
              text-align: center;
              line-height: 23px;
              background: rgba(189, 44, 33, 0.05);
              border: 1px dashed #BD2C21;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #BD2C21;
              margin-right: 38px;
            }

            .goodsName {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }

          .on2 {
            width: 650px;
            height: 100px;
            background: rgba(185, 33, 21, 0.05);
            padding: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            >div {
              width: 80px;
              text-align: left;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 20px;

              >div:nth-child(2) {
                margin-top: 20px;
              }
            }

            >.on2nei {
              text-align: center;
              width: 130px;

              >div:nth-child(1) {
                height: 23px;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #BD2C21;
                line-height: 20px;
              }

              >div:nth-child(2) {
                height: 16px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #505050;
                line-height: 20px;
                margin-top: 14px;
              }
            }
          }

          .on3 {
            display: flex;
            height: 36px;
            line-height: 36px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;

            >div:nth-child(1) {
              color: #000000;
              width: 70px;
            }

            >div:nth-child(2) {
              color: #505050;
            }
          }

          .on4 {
            line-height: 55px;
            display: flex;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;

            >div:nth-child(1) {
              color: #000000;
              width: 70px;
            }

            >div:nth-child(2) {
              display: flex;
              align-items: center;

              >.button {
                width: 130px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                margin-right: 20px;
              }

              >.active {
                background: rgba(185, 33, 21, 0.05);
                border: 1px solid #B92115;
                color: #B92115;
              }
            }
          }

          .on5 {
            line-height: 55px;
            display: flex;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;

            >div:nth-child(1) {
              color: #000000;
              width: 70px;
            }

            >div:nth-child(2) {
              display: flex;
              align-items: center;
            }
          }

          .on6 {
            display: flex;
            align-items: center;
            height: 80px;

            >div {
              width: 150px;
              height: 50px;
              background: #FFFFFF;
              border: 1px solid #E5E5E5;
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #505050;
              line-height: 50px;
              text-align: center;
              margin-left: 20px;
            }

            >div:nth-child(1) {
              background: rgba(185, 33, 21, 0.05);
              border: 1px solid #B92115;
              color: #B92115;
            }

            >div:nth-child(2) {
              background: #B92115;
              border: 1px solid #B92115;
              color: #FFFFFF;
            }

            // >div:nth-child(3){

            // }
          }

          .on7 {
            width: 70px;
            height: 70px;
            position: absolute;
            right: 60px;
            top: 0;
          }
        }
      }

      .leftbottom {
        width: 100%;
        height: 360px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-right: none;
        margin-top: 22px;

        .title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #C33529;
          margin: 16px 20px;
        }

        .list {
          width: 100%;
          height: 305px;
          display: flex;
          overflow-x: scroll;

          .item {
            width: 200px;
            height: 250px;
            flex: 1;
            margin: 0 10px;

            >div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 50px;

              >div:nth-child(1) {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #C33529;
              }

              >div:nth-child(2) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #909090;
              }
            }

            img {
              width: 200px;
              height: 200px;
            }
          }
        }
      }
    }

    .right {
      width: 170px;

      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 40px;
          height: 1px;
          background-color: #DCDCDC;
          left: 8px;
          top: 50%;
        }

        &::after {
          content: '';
          position: absolute;
          width: 40px;
          height: 1px;
          background-color: #DCDCDC;
          right: 8px;
          top: 50%;
        }
      }

      .list {
        width: 100%;
        text-align: center;

        >div:nth-child(1) {
          position: relative;

          .jia {
            position: absolute;
            bottom: 0;
            left: 20px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            background-color: rgba(255, 255, 255, 0.8);
          }

          img {
            width: 130px;
            height: 130px;
            display: inline-block;
          }
        }
      }

      .zuoyou {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }

  .bo {
    display: flex;
    align-items: flex-start;

    .left1 {
      width: 280px;
      border: 1px solid #EEEEEE;
      margin-top: 20px;
      margin-right: 20px;

      .title {
        width: 280px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #F7F7F7;
        border-bottom: 1px solid #EEEEEE;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }

      .list {
        padding: 30px 75px;

        .item {
          width: 100%;
          text-align: center;

          img {
            width: 130px;
            height: 130px;
            display: inline-block;
            margin-bottom: 8px;
          }

          >div:nth-child(2) {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }

          >div:nth-child(3) {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #C33529;
            margin-bottom: 20px;
          }
        }
      }
    }

    .right1 {
      width: 1100px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-top: 20px;

      .top {
        width: 100%;
        height: 50px;
        background: #F7F7F7;
        border-bottom: 1px solid #EEEEEE;
        display: flex;

        >div {
          height: 50px;
          width: 150px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }

        .sta {
          color: #C33529;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 150px;
            height: 2px;
            background: #C33529;
          }
        }
      }

      .xiang {
        width: 100%;
        // height: 800px;
        padding: 30px;

        .img {
          width: 100%;
          height: 70%;
        }
      }

      .pinglun {
        width: 100%;
        padding: 30px;

        .sty {
          width: 100%;
          height: 70px;
          border-bottom: 1px solid #EEEEEE;
          display: flex;

          >div {
            width: 100px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #505050;
            margin-right: 20px;
          }

          .sta {
            background: #C33529;
            color: #FFFFFF;
            border: 1px solid #C33529;
          }
        }

        .list {
          width: 100%;

          .item {
            display: flex;
            padding-top: 20px;

            .img {
              margin-right: 10px;
            }

            .right2 {
              .q1 {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #1B1717;
              }

              .q2 {
                margin-bottom: 10px;

                .ant-rate {
                  font-size: 12px;
                }

                .fen {
                  margin-left: 10px;
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #BD2C21;
                }
              }

              .q3 {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #505050;
              }

              .q4 {
                .ant-image-mask {
                  text-align: center;
                }

                .ant-image {
                  margin-top: 10px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}