.storeList {
  width: 1400px;
  margin: auto;

  .homeToplo {
    display: flex;
  }

  .hometop {
    width: 1019px;
  }

  >div {
    flex: 1;
  }

  .login {
    width: 334px;
    height: 76px;
    margin-right: 47px;
  }

  .ant-input-group {
    font-size: 16px !important;

    .ant-input {
      height: 50px;
      width: 440px;
      font-size: 16px;
    }

    .ant-input-group-addon .ant-select {
      width: 160px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      text-align: left;
    }

    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 50px;
      align-items: center;
    }

    .anticon svg {
      width: 18px;
      height: 20px;
    }

    .ant-select-arrow {
      top: 45%;
      width: 18px;
      height: 20px;
    }
  }

  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 50px;
    width: 60px;
  }

  .flex1 {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
  }

  .svg {
    .anticon svg {
      font-size: 40px;
    }
  }

  .searchright1 {
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    .one {
      margin-left: 20px;

      >div:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        color: #505050;
      }

      >div:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: #C7C7C7;
      }
    }
  }

  .list {
    width: 1019px;
    height: 32px;
    display: flex;
    align-items: center;

    >div {
      width: 157px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909090;
      margin-right: 40px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &>div:hover {
      opacity: 0.6;
    }
  }

  .shopList {
    margin-top: 50px;
    .shopItem {
      display: flex;
      padding: 20px;
      width: 1400px;
      height: 200px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;

      .shopItemLeft {
        display: flex;

        .shopLogo {
          margin-right: 10px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }

        .shopInfoRight {
          padding-top: 10px;
          // display: flex;
          width: 220px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #505050;

          .shopInfoName {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #505050;
            line-height: 20px;
          }
        }
      }

      .rule {
        margin-top: 30px;
        width: 1px;
        height: 100px;
        background: #E5E5E5;
      }
    }
  }

  .shopItemRight {
    display: flex;

    .shopItemGoods {
      width: 140px;
      height: 165px;
      background: #FFFFFF;
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 0 !important;
      margin-left: 20px;

      &:nth-child(5n) {
        margin-right: 0px;
      }

      .img {
        width: 140px;
        height: 140px;
      }

      .you {
        .youpin {
          width: 70px;
          height: 25px;
          background: rgba(189, 44, 33, 0.05);
          border: 1px dashed #BD2C21;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #BD2C21;
          text-align: center;
          line-height: 23px;
          border-radius: 2px;
          margin-right: 10px;
        }

        .name {
          width: 116px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          text-align: center;

        }
      }
    }
  }

}