.outer {
  width: 100%;
  height: 40px;
  background: #F9F9F9;

  .interior {
    width: 1400px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    font-family: Microsoft YaHei;

    .right {
      display: flex;
      width: 520px;
      align-items: center;
      justify-content: space-between;
      text-align: right;

      div {
        position: relative;
      }

      div {
        div:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }

      div::after {
        content: '';
        width: 1px;
        height: 15px;
        background-color: rgba(67, 67, 67, 1);
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%) scaleX(0.5);
      }

      div:nth-last-child(1)::after {
        content: '';
        width: 0;
        height: 0;
      }

      .iconuser {
        color: #B92115;
        margin-right: 6px;
        width: 15px;
        height: 16px;
      }

      .iconipo {
        width: 11px;
        height: 16px;
        color: #B92115;
        margin-right: 6px
      }
    }
  }
}

.eee {
  width: 100px;
  height: 100px;
}