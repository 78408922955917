.hotlist {
  display: flex;
  justify-content: space-between;
  width: 1400px;
  margin: 30px auto 0;


  .hotlistTop {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #EEEEEE;

    .left {
      display: flex;
      line-height: 30px;

      .red {
        width: 8px;
        height: 30px;
        background: #E64F38;
        margin-right: 15px;
      }

      .title {
        font-size: 28px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  .hotlistleft {
    overflow: auto;
    padding: 30px;
    width: 690px;
    height: 800px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .content {
      .item {
        cursor: pointer;
        margin-top: 50px;
        padding: 37px 29px 24px 0;
        border-bottom: 1px solid #EEEEEE;

        &:nth-child(1) {
          margin-top: 10px;
        }

        .title {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #000000;
        }

        .time {
          margin-top: 8px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #909090;
        }

        .neirong {
          height: 40px;
          margin-top: 18px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #909090;
          .itemContent{
            height: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

  }

  .hotlistright {
    padding: 30px;
    width: 690px;
    height: 800px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 0px rgba(158, 158, 158, 0.2);

    .content {
      .item {
        cursor: pointer;
        margin-top: 50px;
        display: flex;
        padding: 30px 0 18px;
        border-bottom: 1px solid #EEEEEE;

        &:nth-child(1) {
          margin-top: 10px;
        }

        .itemleft {
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

          .name {
            text-align: center;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #000000;
          }
        }

        .itemright {
          margin-left: 21px;

          .neirong {
            margin-bottom: 28px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #000000;
            .itemContent{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }

          .dizhi {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #505050;
          }

          .time {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #909090;
          }
        }
      }
    }
  }
}