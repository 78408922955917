.myfuwu {
  width: 100%;
  min-height: 572px;

  .myfuwuitem {
    width: 100%;
    height: 131px;
    padding: 20px;
    border-bottom: 1px solid #EEEEEE;

    .itemtitle {
      .wen {
        display: inline-block;
        margin-right: 11px;
        width: 25px;
        height: 25px;
        background: #DA1D0F;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        line-height: 25px;
      }

      .titletext {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }

    .iteminfo {
      padding-left: 36px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;

      .infoleft {
        .infolefttop {
          margin-bottom: 14px;
          margin-top: 14px;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 11px;
          }

          .name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }
        }

        .infoleftbottom {
          .liulan {
            display: inline-block;
            width: 171px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }

          .shijian {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909090;
          }
        }
      }

      .inforight {
        width: 90px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        background: #FFFFFF;
        border: 1px solid #D2D2D2;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #909090;
      }
      .chakanxiangqing{
        cursor: pointer;
      }
    }
  }
}