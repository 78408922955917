.news{
  .list{
    min-height: 500px;
    .title{
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin-top: 30px;
    }
    .time{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #909090;
    }
  }
}