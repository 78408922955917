.rcserve {
  margin: 20px auto;
  width: 1400px;
  .tiwen {
    cursor: pointer;
    top: 555px;
    right: 266px;
    z-index: 999;
    position: fixed;
    width: 89px;
    height: 89px;

    img {
      width: 100%;
      height: 100%;
      image-rendering: -moz-crisp-edges;
      /* Firefox */
      image-rendering: -o-crisp-edges;
      /* Opera */
      image-rendering: -webkit-optimize-contrast;
      /*Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
      /* IE (non-standard property) */
    }
  }
  .tiwenspan {
    margin-right: 11px;
    display: inline-block;
    width: 73px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
  }
  .rcserveList {
    overflow: auto;
    overflow: hidden;
    width: 100%;
    // transform: translate(0, 0);
    -webkit-column-width: 448px;
    -moz-column-width: 448px;
    -o-colum-width: 448px;
    -webkit-column-gap: 1px;
    -moz-column-gap: 1px;
    -o-column-gap: 1px;



    .item {
      cursor: pointer;
      height: 100%;
      overflow: auto;
      margin-bottom: 10px;
      float: left;
      padding: 25px;
      width: 448px;
      // min-height: 180px;
      background: #F7F7F7;
      border-radius: 4px;

      .itemTop {
        display: flex;

        .wen {
          float: left;
          margin-right: 11px;
          line-height: 25px;
          text-align: center;
          width: 25px;
          height: 25px;
          background: #DA1D0F;
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
        }

        .title {
          width: 399px;
          float: left;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        }
      }

      .pic {
        margin-top: 9px;

        img {
          margin-right: 10px;
          width: 70px;
          height: 70px;
          border-radius: 4px;
        }
      }

      .zhong {
        margin-bottom: 10px;
        display: flex;

        .tou {
          margin-right: 11px;
          width: 25px;
          height: 25px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #909090;
        }
      }

      .content {
        display: flex;
        margin-bottom: 29px;
        .tiwenspan {
          margin-right: 11px;
          display: inline-block;
          width: 73px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
        }
        .da {
          text-align: center;
          line-height: 25px;
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background: #FF9500;
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
        }

        .daan {
          width: 399px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        }
      }

      .itemBottom {
        display: flex;
        justify-content: space-between;

        .look {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #909090;
        }

        .time {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #909090;
        }
      }
    }

  }
  .loadMore{
    cursor: pointer;
    text-align: center;
    font-size: 20px;
  }
}

.tiwenModal {
  .ant-modal-content {
    width: 800px;
    height: 700px;
    background: #FFFFFF;
  }

  .ant-modal-footer {
    text-align: center;
    border-top: 0;
    margin-top: 60px;

    .ant-btn {
      width: 120px;
      height: 40px;
      background: #BD2C21;
      font-size: 16px;
      font-family: PingFang;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

  .content {
    display: flex;
    position: relative;


    .tiwentext {
      resize: none;
      width: 600px;
      height: 80px;
      .ant-input{
        height: 100%;
      }
    }

    .textNum {
      bottom: 2px;
      right: 105px;
      position: absolute;
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #C7C7C7;
    }

  }

  .upload {
    display: flex;

    .uploadSpan {
      margin-right: 11px;
      width: 62px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #000000;

      span:nth-child(2) {
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #C7C7C7;
      }
    }
  }

}