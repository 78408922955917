.shoucang {
  .ant-tabs {
    width: 1400px;
    margin: auto;
.ant-tabs-nav{
  background: #F9F9F9;
}
    .ant-tabs-tab {
      width: 150px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 20px;
      justify-content: center;
    }
  }
}